import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdHome,
  MdWidgets,
  MdSettings,
  MdAnnouncement,
} from "react-icons/md";
import { FaStream, FaUsers } from "react-icons/fa";
import { RiRobot2Fill } from "react-icons/ri";
import { TbPrompt } from "react-icons/tb";

export const urls = {
  dashboard: "/",
  threads: "/conversation",
  askmeAi: "/ask-ai",
  users: "/users",
  prompts: "/suggested-prompts",
  streaming:"/streaming-avatar",
  assistant:"/assistants",
  widgets: "/widgets",
  statistics: "/statistics",
  settings: "/settings",
  signIn: "/sign-in",
  announcement:"/announcement"
};

export const sidebarLinks = [
  {
    name: "Dashboard",
    path: urls.dashboard,
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  },
  {
    name: "Conversations",
    path: urls.threads,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    secondary: true,
  },
  {
    name: "Suggested Prompts",
    path: urls.prompts,
    icon: <Icon as={TbPrompt} width="20px" height="20px" color="inherit" />,
  },
  {
    name: "Streaming Avatar",
    path: urls.streaming,
    icon: <Icon as={FaStream} width="20px" height="20px" color="inherit" />,
  },
  {
    name: "Ask AI",
    path: urls.askmeAi,
    icon: <Icon as={RiRobot2Fill} width="20px" height="20px" color="inherit" />,
    secondary: true,
  },
  {
    name: "Admins",
    icon: <Icon as={FaUsers} width="20px" height="20px" color="inherit" />,
    path: urls.users,
  }, 
  {
    name: "Widgets",
    path: urls.widgets,
    icon: <Icon as={MdWidgets} width="20px" height="20px" color="inherit" />,
  },
  {
    name: "Statistics",
    path: urls.statistics,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  },
  {
    name: "Announcement",
    path: urls.announcement,
    icon: <Icon as={MdAnnouncement} width="20px" height="20px" color="inherit" />,
  },
  {
    name: "Settings",
    path: urls.settings,
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
  },
];
