import { Box, Button, Card, Flex, Heading, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useParams, useNavigate } from 'react-router-dom';
import './style.css';

import { useSelector } from 'react-redux';
import { getAnnouncement } from '../api/announce';
import Loader from 'components/loader/Loader';

const addTargetToLinks = (html) => {
    return html.replace(/<a (?![^>]*target=)([^>]*)>/g, '<a $1 target="_blank">');
};

const AnnouncementDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [announcement, setAnnouncement] = useState(null);
    const [loading, setLoading] = useState(true);
    const background = useColorModeValue("#fff", "#323232");
    const textColor = useColorModeValue("#000", "#fff");
    const linkColor = useColorModeValue("#2b6cb0", "#81e6d9");
    const tooltipBg = useColorModeValue("#000", "#434343");
    const tooltipColor = useColorModeValue("#ffffff", "#fff");
    const assistantId = useSelector(
        (state) => state.admin.selectedAssistant
    );
    useEffect(() => {
        const fetchAnnouncement = async () => {
            try {
                const data = await getAnnouncement(assistantId?.id, id);
                console.log(data);
                setAnnouncement(data);
            } catch (error) {
                console.error('Error fetching announcement:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAnnouncement();
    }, [id, assistantId]);

    if (loading) {
        return <Box pt={{ base: "130px", md: "80px", xl: "80px" }} position="relative"><Loader/></Box>;
    }

    if (!announcement) {
        return <Box>No announcement found.</Box>;
    }

    const title = announcement.title || 'No Title';
    const longDescription = announcement.long_description || 'This Field is Empty';
    const createdAt = announcement.created_at ? new Date(announcement.created_at).toLocaleString() : 'No Date';
    const active = announcement.active ? 'Yes' : 'No';

    return (
        <Card position='relative' top={{base:'120px', md:'80px'}} bg={background} p={3} boxShadow="md" borderRadius={10} height='83vh' overflowY='auto' style={{scrollbarWidth:'none'}}>
            <Button position='absolute' top={0} left={0} borderRadius='10px 0 32px 0' variant='brand' onClick={() => navigate(-1)} mb={4}>
                <Tooltip label='Go Back' placement="top" mb={2} bg={tooltipBg} color={tooltipColor}>
                    <Text>
                        <IoIosArrowBack size={20} />
                    </Text>
                </Tooltip>
            </Button>
            <Box mt={10}>
                <Heading size='lg' color={textColor}>Details for: {title}</Heading>
                <Flex gap={3} flexDirection={{base:'column', md:'row', lg:'row'}} justifyContent='space-between'>
                    <Box
                        p={5}
                        maxWidth='2xl'
                        width="100%"
                        color={textColor}
                        className='rich-text'
                        dangerouslySetInnerHTML={{
                            __html: addTargetToLinks(longDescription)
                                .replace(/<a /g, `<a style="color: ${linkColor}; text-decoration: underline;" `)
                                .replace(/<a([^>]*)>/g, `<a$1 style="color: ${linkColor}; text-decoration: underline;">`)
                                .replace(/<\/a>/g, `</a>`)
                        }}
                    />
                    <Box p={5} borderTop={{base: `1px solid ${background === "#fff" ? "#c7c7c7" : "#4a5568"}`, md:'none'}} borderLeft={{base: 'none', md:`1px solid ${background === "#fff" ? "#c7c7c7" : "#4a5568"}`}} >
                        <Box display='flex' mb={2} gap={1} flexWrap='wrap'>
                            <Text fontWeight="bold" color={textColor}>ID:</Text>
                            <Text color={textColor}>{announcement.id}</Text>
                        </Box>
                        <Box display='flex' mb={2} gap={1} flexWrap='wrap'>
                            <Text fontWeight="bold" color={textColor}>Description:</Text>
                            <Text color={textColor}>{announcement.description}</Text>
                        </Box>
                        <Box display='flex' mb={2} gap={1} flexWrap='wrap'>
                            <Text fontWeight="bold" color={textColor}>Created At:</Text>
                            <Text color={textColor}>{createdAt}</Text>
                        </Box>
                        <Box display='flex' mb={2} gap={1} flexWrap='wrap'>
                            <Text fontWeight="bold" color={textColor}>Assistant ID:</Text>
                            <Text color={textColor}>{assistantId?.id || 'No Assistant ID'}</Text>
                        </Box>
                        <Box display='flex' mb={2} gap={1} flexWrap='wrap'>
                            <Text fontWeight="bold" color={textColor}>Active:</Text>
                            <Text color={textColor}>{active}</Text>
                        </Box>
                    </Box>
                </Flex>
            </Box>

        </Card>
    );
};

export default AnnouncementDetail;