import { Box } from "@chakra-ui/react";
import React from "react";

const Mobile = ({ url }) => {
  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center" // Centering text vertically
      height="300px"
      width="160px"
      border="4px solid black"
      borderRadius="2xl"
      bg="gray.50"
      overflow="hidden"
    >
      <iframe
        src={url}
        title="mobile"
        width="100%"
        height="100%"
        style={{ borderRadius: "5px", border: "none" }}
      />
      <Box
        position="absolute"
        top="0"
        border="1px solid black"
        bg="black"
        width="20"
        height="2"
        borderBottomRightRadius="xl"
        borderBottomLeftRadius="xl"
      />
      <Box
        position="absolute"
        right="-2"
        top="14"
        border="4px solid black"
        height="7"
        borderRadius="md"
      />
      <Box
        position="absolute"
        right="-2"
        bottom="36"
        border="4px solid black"
        height="10"
        borderRadius="md"
      />
    </Box>
  );
};

export default Mobile;
