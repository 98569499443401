import {
  Box, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdDelete, MdEdit, MdVisibility } from 'react-icons/md';
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import EditAnnouncement from './EditAnnouncement';
import DeleteAnnouncement from './DeleteAnnouncement';
import TruncatedCell from './TruncardCell';
import { useNavigate } from 'react-router-dom';
  
const AnnounceTable = ({ response, onUpdate, assistantId }) => {
  const tableBackground = useColorModeValue("white", "#323232");
  const tableBorder = useColorModeValue("gray.200", "#434343");
  const headers = response.length > 0 ? Object.keys(response[0]).filter(header => header !== 'long_description') : [];
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime()) ? date.toLocaleString() : "Invalid Date";
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    onEditOpen();
  };

  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    onDeleteOpen();
  };

  const handleViewClick = (item) => {
    console.log(item);
    navigate(`/announcement/${item.id}`);
  };

  return (
    <Box w="100%" overflowX="auto" bg={tableBackground} p={4} marginTop={4} borderRadius={10}>
      <Table variant="simple">
        <Thead>
          <Tr>
            {headers.map((header) => (
              <Th key={header} fontWeight={700} px="20px" color="gray.400" borderColor={tableBorder}>
                {header.replace(/_/g, ' ')}
              </Th>
            ))}
            <Th fontWeight={700} px="20px" color="gray.400" borderColor={tableBorder}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {response.length > 0 ? response.map((item) => (
            <Tr key={item.id}>
              <TruncatedCell text={item.id.toString()} length={10} />
              <TruncatedCell text={formatDate(item.created_at)} length={30} />
              <TruncatedCell text={item.assistant_id.toString()} length={10} />
              <TruncatedCell text={item.description} length={20} />
              <TruncatedCell text={item.title} length={15} />
              <Td px="20px" border="none">
                {item.active ? <TiTick color='green' size={25} /> : <RxCross2 color='red' size={20} />}
              </Td>
              <Td px="20px" border="none" display="flex" gap={3}>
                <MdVisibility cursor="pointer" size={18} onClick={() => handleViewClick(item)} />
                <MdEdit cursor="pointer" size={18} onClick={() => handleEditClick(item)} />
                <MdDelete cursor="pointer" size={18} color='red' onClick={() => handleDeleteClick(item)} />
              </Td>
            </Tr>
          )) : (
            <Tr>
              <Td colSpan={headers.length + 1} textAlign="center">No data available</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <EditAnnouncement isOpen={isEditOpen} onClose={onEditClose} item={selectedItem} onUpdate={onUpdate} />
      <DeleteAnnouncement
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        assistantId={assistantId}
        item={selectedItem}
        onUpdate={onUpdate}
      />
    </Box>
  );
};
  
export default AnnounceTable;