import React from "react";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import { useSelector } from "react-redux";

export function SidebarBrand() {
  const selectedAssistant = useSelector(
    (state) => state.admin.selectedAssistant
  );
  const textColor = useColorModeValue("gray.700", "white.300");
  return (
    <Flex align="center" direction="column">
      <Flex align="center" direction="column" gap={2} mr={3} my={6}>
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Askme AI Business
        </Text>

        <Text fontSize="lg" color={textColor} fontWeight="bold">
          {selectedAssistant?.name}
        </Text>
      </Flex>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
