import { Box } from '@chakra-ui/react';
import Chat from './components/Chat';

const AskAi = () => {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} display='flex' justifyContent='center' overflow='hidden'>
      
      <Chat/>
      
    </Box>
  );
}

export default AskAi;
