import { Box, Button, Code, Flex, Heading, useColorModeValue, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';

const CopyCode = ({ assistantData }) => {
  const codeBg = useColorModeValue("#fff", "#323232");
  const [copied, setCopied] = useState(false);
  const toast = useToast();
  const code = assistantData?.widget_code;

  const handleCopy = () => {
    setCopied(true);
    toast({
      title: "Code copied.",
      description: "The widget HTML code has been copied to your clipboard.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box mb={[2, 4]} maxWidth="100%">
      <Flex justify="space-between" align="center" mb={2}>
        <Heading size="sm">Widget HTML code:</Heading>
        <CopyToClipboard text={code} onCopy={handleCopy}>
          <Button size="sm">{copied ? "Copied" : "Copy Code"}</Button>
        </CopyToClipboard>
      </Flex>
      <Box
        height="200px" // Fixed height
        overflow="auto" // Enable scrolling
        bg={codeBg}
        borderRadius={10}
        p={4}
      >
        <Code bg="transparent" maxWidth="100%">{code}</Code>
      </Box>
    </Box>
  )
}

export default CopyCode;
