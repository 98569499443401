import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { createStreamingAvatar } from '../api/streaming';

const AddNewStreaming = ({ isOpen, onClose, onAdd,assistantId }) => {
  const cardBackground = useColorModeValue("white", "#323232");
  const [voiceID, setVoiceID] = useState('');
  const [avatarId, setAvatarId] = useState('');
  const [posterUrl, setPosterUrl] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const avatarData = { voiceId: voiceID, avatarId: avatarId, posterUrl: posterUrl };
      const newAvatar = await createStreamingAvatar(assistantId?.id,avatarData);
      onAdd(newAvatar);
      setVoiceID('');
      setPosterUrl('');
      setAvatarId('');
      onClose();
    } catch (error) {
      setError('Failed to create Streaming avatar. Please try again.');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter='blur(10px) hue-rotate(90deg)' />
      <ModalContent bg={cardBackground} maxWidth='4xl' width='100%'>
        <ModalHeader>Add New Streaming Avatar</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl pb={2} as="form" isInvalid={!!error} onSubmit={handleSubmit}>
            <FormLabel htmlFor="voiceID">Voice ID</FormLabel>
            <Input
              id="voiceID"
              placeholder="Please enter your voice ID..."
              value={voiceID}
              onChange={(e) => setVoiceID(e.target.value)}
              mb={3}
            />
            <FormLabel htmlFor="avatarId">Avatar ID</FormLabel>
            <Input
              id="avatarId"
              placeholder="Please enter your avatar ID..."
              value={avatarId}
              onChange={(e) => setAvatarId(e.target.value)}
              mb={3}
            />
            <FormLabel htmlFor="posterUrl">Poster URL</FormLabel>
            <Input
              id="posterUrl"
              placeholder="Please enter your poster URL..."
              value={posterUrl}
              onChange={(e) => setPosterUrl(e.target.value)}
              mb={3}
            />
            <Button variant="brand" fontWeight="500" type="submit">
              Add New
            </Button>
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddNewStreaming
