const BE_API = `${process.env.REACT_APP_BE_URL}/api`

export async function getAllAdmins(assistantId) {
    try {
        const response = await fetch(`${BE_API}/assistants/${assistantId}/admins`);
        if (!response.ok) {
            throw new Error('Failed to retrieve admins');
        }
        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export async function createAdmin(assistantId, name, email, password) {
    try {
        const response = await fetch(`${BE_API}/assistants/${assistantId}/admins`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email, password }),
        });
        if (!response.ok) {
            throw new Error('Failed to create admin');
        }
        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export async function getAdminById(assistantId, id) {
    try {
        const response = await fetch(`${BE_API}/assistants/${assistantId}/admins/${id}`);
        if (!response.ok) {
            throw new Error('Failed to retrieve admin');
        }
        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export async function updateAdmin(assistantId, id, name, phone, password) {
    try {
        const response = await fetch(`${BE_API}/assistants/${assistantId}/admins/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, phone, password }),
        });
        console.log('api response', response);
        
        if (!response.ok) {
            throw new Error('Failed to update admin');
        }
        return response.json();
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export async function deleteAdmin(assistantId, id) {
    try {
        const response = await fetch(`${BE_API}/assistants/${assistantId}/admins/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        
        if (!response.ok) {
            const errorDetails = await response.text(); // Get error details if available
            throw new Error(`HTTP error! Status: ${response.status}, Details: ${errorDetails}`);
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error in deleteAdmin:', error);
        return null;
    }
}

export async function assignAdmin(assistantId, adminId) {
    try {
        const response = await fetch(`${BE_API}/assistants/${assistantId}/admins/${adminId}/assign`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ adminId, assistantId }),
        });
        if (!response.ok) {
            throw new Error('Failed to assign admin');
        }
        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export async function getAssignedAdmins(assistantId, id) {
    try {
        const response = await fetch(`${BE_API}/assistants/${assistantId}/admins/${id}/assistants`);
        if (!response.ok) {
            throw new Error('Failed to retrieve assigned admins');
        }
        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}
