import {
    Box, Table, Tbody, Td, Th, Thead, Tr,
    useColorModeValue, useDisclosure
} from '@chakra-ui/react';
import React from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';

const UserTable = ({ users, assistantId, setSelectedUser, selectedUser, setUsers, onUpdate }) => {
    const tableBackground = useColorModeValue("white", "#323232");
    const tableBorder = useColorModeValue("gray.200", "#434343");

    const excludedHeaders = ['assistant_admins', 'id', 'auth_users_profile', 'auth_users_profile_id'];
    const customHeaders = ['Email', 'Phone Number', 'Last Login'];

    let headers = users && users.length > 0 
        ? Object.keys(users[0]).filter(header => !excludedHeaders.includes(header))
        : [];

    const insertIndex = 1;
    headers.splice(insertIndex, 0, ...customHeaders);

    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return !isNaN(date.getTime()) ? date.toLocaleString() : "Invalid Date";
    };

    const handleEditClick = (item) => {
        setSelectedUser(item);
        onEditOpen();
    };

    const handleDeleteClick = (item) => {
        setSelectedUser(item);
        onDeleteOpen();
    };

    return (
        <Box w="100%" overflowX="auto" bg={tableBackground} p={4} marginTop={4} borderRadius={10}>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        {headers.map((header) => (
                            <Th key={header} fontWeight={700} color="gray.400" borderColor={tableBorder}>
                                {header.replace(/_/g, ' ')}
                            </Th>
                        ))}
                        <Th fontWeight={700} color="gray.400" borderColor={tableBorder}>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {users && users.length > 0 ? users.map((item) => (
                        <Tr key={item.id}>
                            <Td border="none" fontSize={12}>{item.name}</Td>
                            <Td border="none" fontSize={12}>{item?.auth_users_profile?.email}</Td>
                            <Td border="none" fontSize={12}>{item?.auth_users_profile?.phone === null ? "No phone number" : item?.auth_users_profile?.phone}</Td>
                            <Td border="none" fontSize={12}>{formatDate(item?.auth_users_profile?.last_sign_in_at)}</Td>
                            <Td border="none" fontSize={12}>{formatDate(item?.auth_users_profile?.created_at)}</Td>
                            <Td border="none" display="flex" gap={3}>
                                <MdEdit cursor="pointer" size={18} onClick={() => handleEditClick(item)} />
                                <MdDelete cursor="pointer" size={18} color='red' onClick={() => handleDeleteClick(item)} />
                            </Td>
                        </Tr>
                    )) : (
                        <Tr>
                            <Td colSpan="4" textAlign="center">No data available</Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>

            {selectedUser && (
                <>
                    <EditUser
                        isOpen={isEditOpen}
                        onClose={onEditClose}
                        item={selectedUser}
                        onUpdate={onUpdate}
                        assistantId={assistantId}
                    />
                    <DeleteUser
                        assistantId={assistantId}
                        isOpen={isDeleteOpen}
                        onClose={onDeleteClose}
                        onUpdate={onUpdate}
                        item={selectedUser}
                    />
                </>
            )}
        </Box>
    );
};

export default UserTable;
