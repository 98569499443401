import React from "react";
import { Box, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Banner from "views/admin/settings/components/Banner";
import banner from "assets/img/auth/banner.png";
import Assistant from "views/admin/settings/components/Assistant";
import Loader from "components/loader/Loader";

const Settings = () => {
  const adminDetails = useSelector((state) => state.admin.adminDetails);
  const totalThreads = useSelector((state) => state.admin.totalThreads);
  const textColorPrimary = useColorModeValue("#000", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  console.log(adminDetails)

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {adminDetails?.assistant_admins ? (
        <>
          <SimpleGrid columns={{ base: 1, md: 1, lg: 3 }}>
            <Banner
              banner={banner}
              avatar={null}
              name={adminDetails?.name ?? ""}
              job="Admin"
              assistants={adminDetails?.assistant_admins?.assistants}
              totalThreads={totalThreads}
            />
          </SimpleGrid>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="10px"
          >
            All Assistants
          </Text>
          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 2, xl: 3 }}
            gap="20px"
            mb="20px"
          >
            {adminDetails?.assistant_admins?.assistants?.length ? (
              <>
                {adminDetails?.assistant_admins?.assistants?.map(
                  (assistant) => (
                    <Assistant
                      key={assistant?.id}
                      boxShadow={cardShadow}
                      image={assistant?.logo_url}
                      assistant={assistant}
                    />
                  )
                )}
              </>
            ) : (
              ""
            )}
          </SimpleGrid>
        </>
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default Settings;
