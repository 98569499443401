import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "assets/css/MiniCalendar.css";
import { Text, Icon, Box, useOutsideClick, useColorModeValue, Button } from "@chakra-ui/react";
import {
  MdChevronLeft,
  MdChevronRight,
  MdOutlineCalendarToday,
} from "react-icons/md";
import Card from "components/card/Card.js";
import { format } from "date-fns";

const CustomCalendar = (props) => {
  const { selectRange, setFilterDates, filterKey, iconSize, ...rest } = props;
  const [value, onChange] = useState([null, null]);
  const [isOpen, setIsOpen] = useState(false);
  const calenderBg = useColorModeValue('#fff', '#514f4f')
  const ref = useRef();

  useOutsideClick({
    ref: ref,
    handler: () => setIsOpen(false),
  });

  useEffect(() => {
    if (setFilterDates && value[0] && value[1]) {
      const formattedRange = value?.map((date) =>
        format(new Date(date), "yyyy-MM-dd")
      );
      setFilterDates((prevState) => ({
        ...prevState,
        [filterKey]: formattedRange,
      }));
    }
  }, [value, filterKey, setFilterDates]);

  return (
    <Box position="relative" display="flex" ref={ref}>
      <Button onClick={() => setIsOpen(!isOpen)}> 
      <Icon
        as={MdOutlineCalendarToday}
        height={iconSize ?? 5}
        width={iconSize ?? 5}
        cursor="pointer"
      />
      </Button>
      {isOpen && (
        <Box position="absolute" top={10} right={0}>
          <Card
            align="center"
            direction="column"
            w="100%"
            maxW="max-content"
            p="20px 15px"
            h="max-content"
            zIndex={999}
            background={calenderBg}
            boxShadow="lg"
            {...rest}
          >
            <Calendar
              onChange={onChange}
              value={value}
              selectRange={selectRange}
              tileContent={<Text color="brand.500"></Text>}
              prevLabel={<Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />}
              nextLabel={
                <Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />
              }
            />
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default CustomCalendar;
