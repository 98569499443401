import { Box, Flex, Heading, Input, Textarea, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

const WidgetDetail = ({ assistantData }) => {
    const background = useColorModeValue("#fff", "#323232")

  return (
    <Box p={5} width={{ base: "100%", md: "40%" }} bg={background} borderRadius={10}>
        <Heading mb={[2, 4]} fontSize="medium">Widget name:</Heading>
        <Input placeholder="Sri-Panwa" value={assistantData?.name} mb={[2, 4]} />
        <Flex mb={[2, 4]} direction={['column', 'row']} gap={2}>
        <Box w={['100%', '50%']} mb={[2, 0]}>
                    <Heading size="sm" mb={2}>Id</Heading>
                    <Input value={assistantData?.id} />
                </Box>
                <Box w={['100%', '50%']} mb={[2, 0]}>
                    <Heading size="sm" mb={2}>Type</Heading>
                    <Input value={assistantData?.type} />
                </Box>
        </Flex>
        <Flex mb={[2, 4]} direction={['column', 'row']} gap={2}>
                <Box mb={[2, 4]} w={['100%', '50%']}>
                    <Heading size="sm" mb={2}>Logo Url</Heading>
                    <Input placeholder="Enter Logo Url" value={assistantData?.logo_url} />
                </Box>
                <Box mb={[2, 4]} w={['100%', '50%']}>
                    <Heading size="sm" mb={2}>Created At</Heading>
                    <Input placeholder="Enter Logo Url" value={assistantData?.created_at} />
                </Box>

            </Flex>
            <Box mb={[2, 4]}>
                <Heading size="sm" mb={2}>Description:</Heading>
                <Textarea resize="none" height={150} placeholder="Widget Description" value={assistantData?.description} />
            </Box>
    </Box>
  )
}

export default WidgetDetail