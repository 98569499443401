import React from "react";
import { Flex, Stat, StatLabel, StatNumber, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";

const formatValue = (value) => {
  if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(1)}M`;
  } else if (value >= 1_000) {
    return `${(value / 1_000).toFixed(1)}K`; 
  } else {
    return value;
  }
};

const MiniStatistics = (props) => {
  const { startContent, endContent, name, value } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const cardBackground = useColorModeValue('white','#323232');
  const formattedValue = formatValue(value);

  return (
    <Card py="15px" bg={cardBackground}>
      <Flex
        my="auto"
        h="100%"
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}
      >
        {startContent}

        <Stat my="auto" ms={startContent ? "18px" : "0px"}>
          <StatLabel
            lineHeight="100%"
            color={textColorSecondary}
            fontSize={{
              base: "sm",
            }}
          >
            {name}
          </StatLabel>
          <StatNumber
            color={textColor}
            fontSize={{
              base: "2xl",
            }}
          >
            {formattedValue}
          </StatNumber>
        </Stat>
        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
};

export default MiniStatistics;
