import { Box, Flex, Link, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AdminNavbarLinks from "./AdminNavbarLinks";

const AdminNavbar = (props) => {
  const { secondary, message, brandText } = props;
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  let mainText = useColorModeValue("black", "white");
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue("lightModeBg.100", "darkModeBg.100");
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  let gap = "0px";

  return (
    <Box position="relative" mx="auto" maxWidth={{ base: "1550px", md: "1100px", xl: "1550px" }}>
      <Box
        boxShadow={navbarShadow}
        ml={{
          base: "0",
          // xl: "250px",
        }}
        // Custom media queries to handle specific screen sizes
        sx={{
          "@media screen and (min-width:900px)": {
            marginLeft: "0px",
            width: "100%", 
          },
          "@media screen and (max-width: 900px) and (max-width: 1100px)": {
            marginLeft: "0",
            width: "100%", 
          },
          "@media screen and (min-width: 1101px) and (max-width: 1400px)": {
            marginLeft: "330px", 
            width: "72%",
          },
          "@media screen and (min-width: 1401px) and (max-width:1550px)": {
            marginLeft: "330px",
            width: "75%", 
          },
          "@media screen and (min-width:1551px) and (max-width:1900px)":{
            marginLeft: "240px",
            width:'87%',
          },
          "@media screen and (min-width:1901px)":{
            marginLeft: "160px",
            width:'99%',
          }
          
        }}
        mr={{ base: "auto", lg: "auto" }}
        mt={secondaryMargin}
        bg={navbarBg}
        borderColor={navbarBorder}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        backgroundPosition="center"
        backgroundSize="cover"
        borderRadius="16px"
        borderWidth="1.5px"
        borderStyle="solid"
        transition="box-shadow 0.25s linear, background-color 0.25s linear, filter 0.25s linear, border 0.25s linear"
        alignItems={{ xl: "center" }}
        display={secondary ? "block" : "flex"}
        minH="75px"
        justifyContent="center"
        lineHeight="25.6px"
        pb="8px"
        px={{
          sm: paddingX,
          md: "10px",
        }}
        ps={{
          xl: "12px",
        }}
        pt="8px"
      >
        <Flex
          w="100%"
          flexDirection={{
            sm: "column",
            md: "row",
          }}
          alignItems="center"
          mb={gap}
        >
          <Box mb={{ sm: "8px", md: "0px" }}>
            {/* Navbar brand */}
            <Link
              color={mainText}
              href="#"
              bg="inherit"
              borderRadius="inherit"
              fontWeight="bold"
              fontSize="34px"
              _hover={{ color: { mainText } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              {brandText}
            </Link>
          </Box>
          <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
            <AdminNavbarLinks
              onOpen={props.onOpen}
              logoText={props.logoText}
              secondary={props.secondary}
              fixed={props.fixed}
              scrolled={scrolled}
            />
          </Box>
        </Flex>
        {secondary ? <Text color="white">{message}</Text> : null}
      </Box>
    </Box>
  );
};

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};

export default AdminNavbar;
