import { Box, SimpleGrid, Button, useToast } from "@chakra-ui/react";
import { BsEyeFill } from "react-icons/bs";
import React, { useState } from "react";
import { format } from "date-fns";
import { useGetAssistantThreadsQuery } from "store/api/threads";
import CustomTable from "components/tables/CustomTable";
import Loader from "components/loader/Loader";
import { useSelector } from "react-redux";
import { getAssistantThreads } from "superbase/apis";
import { convertToCSV } from "utils/convertToCSV";
import { useNavigate } from "react-router-dom";

const Threads = () => {
  const selectedAssistant = useSelector(
    (state) => state.admin.selectedAssistant
  );
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const pageSize = 50;

  const { data, isLoading, isFetching } = useGetAssistantThreadsQuery(
    {
      assistantId: selectedAssistant?.id,
      page,
      pageSize,
    },
    {
      skip: !selectedAssistant?.id,
    }
  );

  // console.log(data)

  const handleViewThread = (id) => {
    navigate(`/conversation/${id}`);
  };


  const handleExport = async () => {
    setLoading(true);
    const { threads, threadError } = await getAssistantThreads(
      selectedAssistant.id,
      data?.totalItems
    );
    if (threadError) {
      setLoading(false);
      toast({
        title: threadError?.message || "failed to fetch threads",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setLoading(false);
      convertToCSV(threads, selectedAssistant?.name, [
        "deleted",
        "request_reference",
        "metadata_fetched",
      ]);
    }
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Message",
      accessor: "first_message",
    },
    {
      Header: "Date",
      accessor: "created_at",
      Cell: ({ value }) => (
        <> {format(new Date(value), "dd-MM-yyyy")}</>
      ),
    },
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Time of Use",
      accessor: "time_of_use",
    },
    {
      Header: "Token",
      accessor: "total_tokens",
    },
    {
      Header: "User Persona",
      accessor: "user_persona",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <Box onClick={() => handleViewThread(row.original?.id)} ml={5}>
          <BsEyeFill size={20} cursor="pointer" />
        </Box>
      ),
    },
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "10px", xl: "10px" }}
      >
        <Button
          variant="brand"
          fontWeight="500"
          onClick={handleExport}
          isDisabled={loading}
          isLoading={loading}
          marginLeft="auto"
          width="max-content"
          minW={100}
        >
          Export
        </Button>

        {data?.threads?.length ? (
          <CustomTable
            columnsData={columns}
            tableData={data?.threads ?? []}
            currentPage={page}
            setCurrentPage={setPage}
            totalItems={data?.totalItems}
            pageSize={pageSize}
            isLoading={isLoading || isFetching}
          />
        ) : (
          <Loader />
        )}
      </SimpleGrid>

      {/* {selectedThread && (
        <ViewThreadModal
          isOpen={isModalOpen}
          selectedThread={selectedThread}
          handleClose={handleCloseModal}
        />
      )} */}
    </Box>
  );
};

export default Threads;
