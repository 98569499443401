import { Box, Flex, Heading, Image, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import Information from "components/Information/Information";
import { format } from "date-fns";
import React from "react";
import AssistantTooltip from "./AssistantTooltip";

const Assistant = (props) => {
  const { assistant, ranking, link, image, ...rest } = props;
  const cardBg = useColorModeValue("white", "#323232");

  return (
    <Card bg={cardBg} {...rest}>
        <AssistantTooltip activeAssistnat={assistant?.id}/>
      <Box  maxWidth='650px' position='relative'>
      <Box gap={3} display='flex' justifyContent='start' alignItems='center'>
          <Image h="80px" w="80px" src={image} borderRadius="100%" />
          <Box >
            <Heading size='md' color='#E56B20'>{assistant?.name}</Heading>
            <Information pl={0} value={assistant?.description} />
          </Box>

        </Box>
        <Box display='flex' gap={3} my={4}>
        <Information title="Assistant ID" pr={0} value={assistant?.id} />
        <Information pl={0}
          title="Website URL"
          value={assistant?.website_url}
          isLink={true}
        />
        </Box>
        <Flex mt={3}>
        <Information title="Type" value={assistant?.type} />
        <Information
          title="Total Threads"
          value={assistant?.threads?.length ?? 0}
        />
        <Information
          title="Created At"
          value={format(new Date(assistant?.created_at), "dd-MM-yyyy")}
        />

        </Flex>
      </Box>
    </Card>
  );
};

export default Assistant;
