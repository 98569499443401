import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { deleteSuggestedPrompt } from '../api/prompt';

const DeletePrompt = ({ isOpen, onClose, item,assistantId, onUpdate}) => {
    const cardBackground = useColorModeValue("white", "#323232");
    const handleConfirm = async () => {
        if (item) {
            try {
                const response = await deleteSuggestedPrompt(assistantId, item.id);
                if (response.message === 'Suggested prompt deleted successfully') {
                    onUpdate(item, 'delete');
                }
            } catch (error) {
                console.error('Failed to delete prompt:', error);
            }
            onClose();
        }
    };
    
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter='blur(10px) hue-rotate(90deg)' />
      <ModalContent bg={cardBackground}>
        <ModalHeader>Delete Suggestion</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Are you sure you want to delete <strong>{item?.suggestion}</strong></p>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} variant="brand" onClick={onClose}>Cancel</Button>
          <Button colorScheme="red" bg="#ff0000" color="#fff" onClick={handleConfirm}>Delete</Button>
            </ModalFooter>
            </ModalContent>
     </Modal>   
  )
}

export default DeletePrompt