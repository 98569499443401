import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
function AuthIllustration(props) {
  const bgColorMode = useColorModeValue("white", "#434343")
  const { children } = props;

  return (
    <Box bg={bgColorMode}>  
    <Flex position="relative" h="max-content" bg={bgColorMode} maxW={{sm:'1500px', md:'100%',lg:'1500px', xl:'1900px'}} mx='auto'>
      <Flex
        h={{
          sm: "100vh",
          md: "unset",
          lg: "100vh",
          xl: "100vh",
        }}
        w="100%"
        // bg="wheat"
        maxW={{ md: "66%", lg: "1313px", xl:'1900px' }}
        mx="auto"
        pt={{ sm: "50px", md: "0px" }}
        px={{ lg: "30px", xl: "0px" }}
        ps={{ xl: "200px" }}
        justifyContent="start"
        direction="column"
      >
        {children}
        <Box
          display={{ base: "none", md: "block" }}
          h="100%"
          minH="100vh"
          w={{ lg: "50vw", "2xl": "44vw" }}
          position="absolute"
          right="0px"
        >
          <Flex
            bg="#3C3C3C"
            justify="center"
            align="end"
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}
          >
            <Flex
              align="center"
              justifyContent="center"
              direction="column"
              h="100%"
            >
              {/* <img
                height={200}
                width={200}
                src="https://cdn.prod.website-files.com/65bd961bf4d7574759293fd1/65bd961cf4d757475929409c_Star%2520Icon-p-500.png"
                alt="logo"
              /> */}
              <Text fontSize="xx-large" color="white" fontWeight="bold">
                Askme AI
              </Text>

              <Text fontSize="lg" color="white">
                Your business analytics Dashboard
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Footer />
      </Flex>
    </Flex>
    </Box>
  );
}

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
