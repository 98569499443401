import React from "react";
import "assets/css/App.css";
import "assets/css/Loader.css";
import { BrowserRouter } from "react-router-dom";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import Routes from "routes/routes";
import { Provider } from "react-redux"; // Import Provider from react-redux
import { store } from "store";
import {
  ThemeProvider,
  CSSReset,
  ColorModeProvider,
  ChakraProvider,
  Container,
} from "@chakra-ui/react";

const App = () => {
  return (
    <ChakraProvider>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <ColorModeProvider>
            <CSSReset />
            <ThemeEditorProvider>
              <Provider store={store}>
                <BrowserRouter>
                <Container maxWidth='1900px' p={0} height='100vh'>
                  <Routes />
                </Container>
                </BrowserRouter>
              </Provider>
            </ThemeEditorProvider>
          </ColorModeProvider>
        </ThemeProvider>
      </React.StrictMode>
    </ChakraProvider>
  );
};

export default App;
