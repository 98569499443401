const BE_API = `${process.env.REACT_APP_BE_URL}/api`

export async function getAllAnnouncement(assistantId){
    try {
        const response = await fetch(`${BE_API}/assistants/${assistantId}/announcements`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching announcements:', error);
        throw error;
      }
}

export async function createAnnouncement (assistantId, title, description,longDescription) {
    try {
      const response = await fetch(`${BE_API}/assistants/${assistantId}/announcements`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ title, description, long_description: longDescription })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error creating announcement:', error);
      throw error;
    }
  };
  export async function getAnnouncement (assistantId, id) {
    try {
      const response = await fetch(`${BE_API}/assistants/${assistantId}/announcements/${id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching announcement:', error);
      throw error;
    }
  };
  export async function updateAnnouncement (assistantId, id, title, description, longDescription){
    try {
      const response = await fetch(`${BE_API}/assistants/${assistantId}/announcements/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ title, description, long_description:longDescription })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // console.log('API Response:', data);
      return data;
    } catch (error) {
      console.error('Error updating announcement:', error);
      throw error;
    }
  };
  export async function deleteAnnouncement(assistantId, id){
    try {
      const response = await fetch(`${BE_API}/assistants/${assistantId}/announcements/${id}`, {
        method: 'DELETE'
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error deleting announcement:', error);
      throw error;
    }
  };
       
  