import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import Loader from "components/loader/Loader";
import { scatterChartData, scatterChartOptions } from "constants/charts";
import ScatterChart from "components/charts/ScatterChart";
import ChartTooltip from "tooltip/ChartTooltip";
import Calendar from "components/calendar";

const ScatterCard = (props) => {
  const {
    data,
    title,
    xAxisTitle,
    yAxisTitle,
    showToolbar,
    isLoading,
    details,
    filterKey,
    setFilterDates,
    showCalendar,
    ...rest
  } = props;
  const heading = useColorModeValue("secondaryGray.900", "secondaryGray.300");
  const textColor = useColorModeValue("#1B2559", "#ffffff");
  const cardBg = useColorModeValue("white", "#323232");

  return (
    <Card
      p="20px"
      align="center"
      bg={cardBg}
      direction="column"
      w="100%"
      h="450px"
      {...rest}
    >
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        alignItems="center"
        w="100%"
        gap={2}
        mb="8px"
      >
        <Text color={heading} fontSize="22px" fontWeight="700">
          {title}
        </Text>
        <ChartTooltip />
        {showCalendar && (
          <Calendar
            selectRange={true}
            setFilterDates={setFilterDates}
            filterKey={filterKey}
          />
        )}
      </Flex>
      {isLoading || !data ? (
        <Loader />
      ) : (
        <ScatterChart
          chartData={scatterChartData(data)}
          chartOptions={scatterChartOptions(
            textColor,
            xAxisTitle,
            yAxisTitle,
            showToolbar
          )}
        />
      )}
    </Card>
  );
};

export default ScatterCard;
