import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchAssistant } from "./api/widgetInfo";
import WidgetDetail from "./component/WidgetDetail";
import LivePreview from "./component/LivePreview";
import CopyCode from "./component/CopyCode";
import { useSelector } from "react-redux";
import Loader from "components/loader/Loader";

const Widget = () => {
  const [assistantData, setAssistantData] = useState(null);
  const [, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const selectedAssistant = useSelector(
    (state) => state.admin.selectedAssistant
  );

  useEffect(() => {
    const getData = async () => {
      if (selectedAssistant?.url) {
        try {
          setLoading(true);
          const data = await fetchAssistant(selectedAssistant.url);
          setAssistantData(data);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        setError("No assistant selected");
      }
    };

    getData();
  }, [selectedAssistant]);
  return (
    <Box
      pt={{ base: "140px", md: "90px", xl: "90px" }}
      position="relative"
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      gap={3}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <WidgetDetail assistantData={assistantData} />
          <Flex
            direction="column"
            width={{ base: "100%", md: "58%", xl: "" }}
            flexWrap="wrap"
          >
            <CopyCode assistantData={assistantData} />
            <LivePreview selectedAssistantName={selectedAssistant} />
          </Flex>
        </>
      )}
    </Box>
  );
};

export default Widget;
