import React from "react";
import { Tooltip, IconButton, useColorModeValue } from "@chakra-ui/react";
import { IoInformationCircleSharp } from "react-icons/io5";

const ChartTooltip = ({details}) => {
    const tooltipBg = useColorModeValue('gray.300', '#434343');
    const tooltipColor = useColorModeValue('#000', '#fff');
  return (
    <Tooltip label={details} placement="bottom" hasArrow p={3} borderRadius={10} bg={tooltipBg} color={tooltipColor}>
      <IconButton
        aria-label="Info"
        icon={<IoInformationCircleSharp />}
        size="lg"
        variant='ghost'
      />
    </Tooltip>
  );
};

export default ChartTooltip;
