import { Box, Link, Text, useColorModeValue } from "@chakra-ui/react";

import Card from "components/card/Card.js";
import React from "react";

export default function Information(props) {
  const { title, value, isLink, ...rest } = props;

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card bg={bg} {...rest} py={2}>
      <Box>
        <Text fontWeight="500" color={textColorPrimary} fontSize="sm">
          {title}
        </Text>
        {isLink ? (
          <Link href={value} isExternal>
            <Text color={textColorSecondary} fontWeight="400" fontSize="md">
              {value}
            </Text>
          </Link>
        ) : (
          <Text color={textColorSecondary} fontWeight="400" fontSize="md">
            {value}
          </Text>
        )}
      </Box>
    </Card>
  );
}
