// import auth from "@app/utils/auth";

// eslint-disable-next-line import/prefer-default-export
export const baseQuery = {
  baseUrl: `${process.env.REACT_APP_BE_URL}/api`,
  prepareHeaders: (headers) => {
    // const token = auth.token();
    // headers.set("Authorization", `Bearer ${token}`);
    return headers;
  },
};
