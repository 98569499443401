import React, { useState, useEffect } from 'react';
import { Box, Button } from '@chakra-ui/react';
import NewPopupForm from './components/NewPopupForm';
import PromptTable from './components/PromptTable';
import { deleteSuggestedPrompt, getSuggestedPrompts } from './api/prompt';
import { useSelector } from 'react-redux';
import Loader from 'components/loader/Loader';

const Prompts = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true)
    const [prompts, setPrompts] = useState([]);
    const assistantId = useSelector(
        (state) => state.admin.selectedAssistant
    );
    console.log(assistantId?.id)
    useEffect(() => {
        const fetchPrompts = async () => {
            try {
                setLoading(true);
                const data = await getSuggestedPrompts(assistantId?.id);
                if (data) {
                    setPrompts(data);
                }
            } catch (error) {
                console.error('Error fetching prompts:', error);
            } finally {
                setLoading(false)
            }
        };

        fetchPrompts();
    }, [assistantId]);

    const handleOpen = () => setIsModalOpen(true);
    const handleClose = () => setIsModalOpen(false);
    const handleUpdate = (item, operationType) => {
        if (operationType === 'update') {
            if (item) {
                setPrompts((prevPrompts) =>
                    prevPrompts.map((prevItem) =>
                        prevItem.id === item.id ? { ...prevItem, suggestion: item.suggestion } : prevItem
                    )
                );
            }
        } else if (operationType === 'delete') {
            if (item) {
                setPrompts((prevPrompts) => prevPrompts.filter((prevItem) => prevItem.id !== item.id));
            }
        } else if (operationType === 'create') {
            if (item) {
                setPrompts((prevPrompts) => [...prevPrompts, item]);
            }
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await deleteSuggestedPrompt(assistantId, id);
            if (response.message === 'Suggested prompt deleted successfully') {
                setPrompts((prevPrompts) => prevPrompts.filter((item) => item.id !== id));
            }
        } catch (error) {
            console.error('Failed to delete prompt:', error);
        }
    };

    const handleAdd = (newItem) => {
        handleUpdate(newItem, 'create');
    };

    return (
        <>
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }} position="relative" minHeight="90vh">
                <Box display='flex' justifyContent='end'>
                    <Button variant="brand" fontWeight="500" marginLeft="auto" width="max-content" minW={100} onClick={handleOpen}>Add New</Button>
                </Box>
                {loading ? (<Loader />) : (
                    <PromptTable
                        data={prompts}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                        assistantId={assistantId}
                    />
                )}
            </Box>
            <NewPopupForm
                isOpen={isModalOpen}
                onClose={handleClose}
                assistantId={assistantId}
                onAdd={handleAdd}
            />
        </>
    );
};

export default Prompts;
