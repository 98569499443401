import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import React from "react";
import { lineChartData, lineChartOptions } from "constants/charts";
import Loader from "components/loader/Loader";
import ChartTooltip from "tooltip/ChartTooltip";
import Calendar from "components/calendar";

const LineCard = (props) => {
  const {
    data,
    colors,
    title,
    categories,
    xAxisTitle,
    yAxisTitle,
    showToolbar,
    isLoading,
    categoryType,
    isSingle,
    details,
    markerSize,
    lineColor,
    offsetY,
    filterKey,
    setFilterDates,
    showCalendar,
    ...rest
  } = props;
  const heading = useColorModeValue("#000", "secondaryGray.300");
  const textColor = useColorModeValue("secondaryGray.900", "#ffffff");
  const cardBg = useColorModeValue("white", "#323232");

  return (
    <Card p="20px" bg={cardBg} direction="column" w="100%" {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        gap={2}
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={heading} fontSize="22px" fontWeight="700">
          {title}
        </Text>
        <ChartTooltip details={details} />
        {showCalendar && (
          <Calendar
            selectRange={true}
            setFilterDates={setFilterDates}
            filterKey={filterKey}
          />
        )}
      </Flex>
      <Box height="100%">
        {isLoading || !data ? (
          <Loader />
        ) : (
          <LineChart
            chartData={lineChartData(data, categories, isSingle)}
            chartOptions={lineChartOptions(
              data,
              colors,
              textColor,
              xAxisTitle,
              showToolbar,
              categoryType,
              markerSize,
              yAxisTitle,
              lineColor,
              offsetY
            )}
          />
        )}
      </Box>
    </Card>
  );
};

export default LineCard;
