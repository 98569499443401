const BE_API = `${process.env.REACT_APP_BE_URL}/api`
export async function getSuggestedPrompts(assistantId) {
    try {
        const response = await fetch(`${BE_API}/assistants/${assistantId}/suggested-prompts`);
        if (response.ok) {
            const data = await response.json();
            // console.log(data);
            return data;
        } else {
            throw new Error('Error retrieving suggested prompts');
        }
    } catch (error) {
        console.error(error);
    }
}

export async function createSuggestedPrompt(assistantId, suggestion) {
    try {
        const response = await fetch(`${BE_API}/assistants/${assistantId}/suggested-prompts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ suggestion })
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error('Error creating suggested prompt');
        }
    } catch (error) {
        console.error(error);
    }
}

export async function getSuggestedPromptById(assistantId, id) {
    try {
        const response = await fetch(`${BE_API}/assistants/${assistantId}/suggested-prompts/${id}`);
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error('Error retrieving suggested prompt');
        }
    } catch (error) {
        console.error(error);
    }
}

export async function deleteSuggestedPrompt(assistantId, id) {
    try {
        const response = await fetch(`${BE_API}/assistants/${assistantId}/suggested-prompts/${id}`, {
            method: 'DELETE'
        });
        if (response.ok) {
            return { message: 'Suggested prompt deleted successfully' };
        } else {
            throw new Error('Error deleting suggested prompt');
        }
    } catch (error) {
        console.error(error);
    }
}

export async function updateSuggestedPrompt(assistantId, id, suggestion) {
    try {
        const response = await fetch(`${BE_API}/assistants/${assistantId}/suggested-prompts/${id}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ assistantId, suggestion }),
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          return Array.isArray(result) ? result[0] : result; 
        } catch (error) {
          console.error('Error updating prompt:', error);
          return null; 
    }
}

// asst_yptQriPUDCJt5x1j3qeyMppC