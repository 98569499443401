import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useColorModeValue } from '@chakra-ui/react';
import React, { useState } from 'react';
import { createAnnouncement } from '../api/announce';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import './style.css'

const AddNew = ({ isOpen, onClose, assistantId, onAdd }) => {
    const cardBackground = useColorModeValue("white", "#323232");
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [longDescription, setLongDescription] = useState('');
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        try {
            // console.log('Long description:', longDescription); 
            const response = await createAnnouncement(assistantId?.id, title, description, longDescription);
            // console.log(response); 
            if (response && response.length > 0) {
                const newItem = {
                    ...response[0],
                    created_at: new Date(response[0].created_at).toISOString()
                };
                onAdd(newItem);
            }
            setTitle('');
            setDescription('');
            setLongDescription('');
            onClose();
        } catch (err) {
            setError('Failed to create announcement. Please try again.');
        }
    };
    

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay bg="blackAlpha.300" backdropFilter='blur(10px) hue-rotate(90deg)' />
            <ModalContent bg={cardBackground} maxWidth='4xl' width='100%'>
                <ModalHeader>Add New Announcement</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl pb={2} as="form" onSubmit={handleSubmit} isInvalid={!!error}>
                        <FormLabel htmlFor="title">Title</FormLabel>
                        <Input
                            id="title"
                            placeholder="Please enter announcement title..."
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            mb={3}
                        />
                        <FormLabel htmlFor="description">Description</FormLabel>
                        <Input
                            id="description"
                            placeholder="Please enter announcement description..."
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            mb={3}
                        />
                        <FormLabel htmlFor="longDescription">Long Description</FormLabel>
                        <ReactQuill 
                            id="longDescription"
                            value={longDescription}
                            onChange={setLongDescription}
                            placeholder="Please enter long description..."
                            style={{ height: '100px',  }}
                            mb={3}
                        />
                        {error && <FormErrorMessage>{error}</FormErrorMessage>}
                        <Button mt='60px' colorScheme="orange" bg="#E56B20" color="white" type="submit">Create</Button>
                    </FormControl>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AddNew;
