import { Box } from "@chakra-ui/react";
import React from "react";

const Laptop = ({ url }) => {
  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="300px"
      width="470px"
      border="4px solid black"
      borderRadius="10px"
      bg="gray.50"
      overflow="hidden"
    >
      <iframe
        src={url}
        title="laptop"
        width="100%"
        height="100%"
        style={{ borderRadius: "5px", border: "none" }}
      />
    </Box>
  );
};

export default Laptop;
