import React from "react";
import { Route, Routes as DOMRoutes } from "react-router-dom";
import ProtectedRoute from "routes/protected/ProtectedRoute";
import Dashboard from "views/admin/dashboard";
import Threads from "views/admin/threads";
import Settings from "views/admin/settings/Settings";
import SignIn from "views/auth/signIn";
import { urls } from "routes/urls";
import AskAi from "views/admin/AskAi";
import Widget from "views/admin/Widget/Widget";
import Prompts from "views/admin/prompts/Prompts";
import Users from "views/admin/users/Users";
import Announcement from "views/admin/announcement/Announcement";
import Statistics from "views/admin/statistics";
import AnnouncementDetail from "views/admin/announcement/components/AnnouncementDetail";
import Streaming from "views/admin/streaming/Streaming";
import StreamingWrapper from "views/admin/streaming/components/StreamWrapper";
import ThreadDetails from "views/admin/threads/components/ThreadDetails";
import AssistantWrapper from "views/admin/assistants/components/AssistantWrapper";
import ErrorPage from "components/404/404Page";

const Routes = () => {
  return (
    <DOMRoutes>
      <Route path={urls.signIn} element={<SignIn />} />
      <Route element={<ProtectedRoute />}>
        <Route path={urls.dashboard} element={<Dashboard />} />
        <Route path={urls.threads} element={<Threads />} />
        <Route path={`${urls.threads}/:id`} element={<ThreadDetails />} />
        <Route path={urls.prompts} element={<Prompts />} />
        <Route path={urls.streaming} element={<Streaming />} />
        <Route path={`${urls.streaming}/:id`} element={<StreamingWrapper />} />
        <Route path={urls.assistant} element={<AssistantWrapper/>} />
        <Route path={urls.askmeAi} element={<AskAi />} />
        <Route path={urls.users} element={<Users />} />
        <Route path={urls.statistics} element={<Statistics />} />
        <Route path={urls.widgets} element={<Widget />} />
        <Route path={urls.announcement} element={<Announcement/>} />
        <Route path={`${urls.announcement}/:id`} element={<AnnouncementDetail/>} />
        <Route path={urls.settings} element={<Settings />} />
      </Route>
      <Route path="*" element={<ErrorPage/>} />
    </DOMRoutes>
  );
};

export default Routes;
