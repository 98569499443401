import { useParams, useLocation } from 'react-router-dom';
import ViewStreaming from './ViewStreaming';
import EditStreaming from './EditStreaming';


const StreamingWrapper = () => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isEdit = searchParams.get('edit') === 'true';

  if (isEdit) {
    return <EditStreaming id={id} />;
  }

  return <ViewStreaming id={id} />;
};

export default StreamingWrapper;
