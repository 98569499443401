export const textStyle = (textColor, fontSize = "14px", fontWeight = "500") => {
  return {
    colors: textColor,
    fontSize,
    fontWeight,
  };
};

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const sortDataByType = (data, type) => {
  if (!Array.isArray(data)) {
    return [];
  }

  switch (type) {
    case "week":
      data.sort((a, b) => {
        return weekDays.indexOf(a) - weekDays.indexOf(b);
      });
      break;

    case "day":
      data.sort();
      break;

    case "date":
      data?.sort((a, b) => new Date(a) - new Date(b));
      break;

    default:
      break;
  }

  return data;
};

export const barChartData = (data) => {
  return [
    {
      name: "Conversation",
      data: Object.values(data),
    },
  ];
};

export const barChartOptions = (
  data,
  xAxisTitle,
  yAxisTitle,
  textColor,
  type,
  barColors,
  showToolbar,
  offsetY
) => {
  if (!barColors || !Array.isArray(barColors)) {
    barColors = ["#E56B20", "#FFA726"];
  }

  return {
    chart: {
      toolbar: {
        show: showToolbar,
        offsetX: 0,
        offsetY: offsetY ?? -68,
      },
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: sortDataByType(Object.keys(data), type),
      show: true,
      labels: {
        show: true,
        style: textStyle(textColor),
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      title: {
        text: xAxisTitle ?? "",
        style: {
          color: textColor,
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: textStyle(textColor),
      },
      title: {
        text: yAxisTitle ?? "",
        style: {
          color: textColor,
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
    },
    grid: {
      position: "back",
      show: true,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: barColors.map((color, index) => ({
          offset: (index * 100) / (barColors.length - 1),
          color: color,
          opacity: 1,
        })),
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
  };
};

export const pieChartOptions = (data, colors, showToolbar, isDarkMode) => {
  const gradientColors = [
    ["#FF5733", "#FF8E33"], // Gradient for Fiery Red
    ["#3385FF", "#33A3FF"], // Gradient for Bright Blue
    ["#75FF33", "#A3FF33"], // Gradient for Vivid Green
    ["#FFBD33", "#FFE733"], // Gradient for Bright Yellow
    ["#33FFF6", "#33DFFF"], // Gradient for Aqua Blue
    ["#8A33FF", "#A333FF"], // Gradient for Vivid Purple
    ["#FF33E3", "#FF33C5"], // Gradient for Bright Pink
    ["#FF3396", "#FF336A"], // Gradient for Hot Pink
    ["#FF3385", "#FF3357"], // Gradient for Neon Pink
    ["#33FF57", "#33FF75"], // Gradient for Bright Lime
  ];

  // Modify inner colors for light mode to be slightly darker for a 3D effect
  const adjustedColors = gradientColors.map(([color1, color2]) => {
    const darkenedColor = isDarkMode ? color2 : darkenColor(color2, 0.2); // Adjust brightness in light mode
    return [color1, darkenedColor];
  });

  return {
    labels: Object.keys(data)?.map((item) => item ?? "Others"),
    colors: adjustedColors.map((gradient) => gradient[0]), // Use the first color of each gradient as the base color
    chart: {
      width: "100%",
      height: "100%",
      toolbar: {
        show: showToolbar,
        offsetX: 0,
        offsetY: -35,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
    },
    hover: { mode: null },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
      donut: {
        expandOnClick: false,
        labels: {
          show: true,
        },
      },
    },
    stroke: {
      curve: "smooth",
      type: "line",
      width: 0,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal", // Use 'diagonal1' or any other type of gradient
        shadeIntensity: 0.5,
        gradientToColors: adjustedColors.map((gradient) => gradient[1]), // Use the adjusted second color of each gradient
        opacityFrom: 0.8,
        opacityTo: 1,
      },
    },
    tooltip: {
      enabled: true,
      theme: isDarkMode ? "dark" : "light",
    },
  };
};
const darkenColor = (color, amount) => {
  let usePound = false;

  if (color[0] === "#") {
    color = color.slice(1);
    usePound = true;
  }

  const num = parseInt(color, 16);
  let r = (num >> 16) - Math.round(255 * amount);
  let g = ((num >> 8) & 0x00ff) - Math.round(255 * amount);
  let b = (num & 0x0000ff) - Math.round(255 * amount);

  r = r < 0 ? 0 : r;
  g = g < 0 ? 0 : g;
  b = b < 0 ? 0 : b;

  return (
    (usePound ? "#" : "") +
    ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")
  );
};
export const pieChartData = (data) => {
  const pieData = Object.values(data);
  return pieData;
};

export const lineChartData = (data, categories, isSingle) => {
  const lineData = categories?.map((category) => ({
    name: category.title,
    data: Object.values(data)?.map((item) =>
      isSingle ? item : item[category.key]
    ),
  }));
  return lineData;
};

export const lineChartOptions = (
  data,
  colors,
  textColor,
  xAxisTitle,
  showToolbar,
  categoryType = "",
  markerSize = 0,
  yAxisTitle,
  lineColors,
  offsetY
) => {
  if (!lineColors || !Array.isArray(lineColors)) {
    lineColors = ["#E56B20", "#FFA726"];
  }
  return {
    chart: {
      toolbar: {
        show: showToolbar,
        offsetX: 0,
        offsetY: offsetY ?? -40,
      },
      zoom: {
        type: "x",
        enabled: false,
        autoScaleYaxis: true,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#000",
      },
    },
    colors: [...lineColors],
    markers: {
      size: markerSize,
      colors: "white",
      strokeColors: lineColors,
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      type: "line",
      width: 4,
      colors: lineColors,
    },
    xaxis: {
      type: "numeric",
      categories: sortDataByType(Object.keys(data), categoryType),
      labels: {
        style: textStyle(textColor),
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      title: {
        text: xAxisTitle ?? "",
        style: {
          color: textColor,
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        style: textStyle(textColor, "14px"),
      },
      title: {
        text: yAxisTitle ?? "",
        style: {
          color: textColor,
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF", "#39B8FF"],
        opacity: 0.5,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "horizontal",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: lineColors.map((color, index) => ({
          offset: (index * 100) / (lineColors.length - 1),
          color: color,
          opacity: 1,
        })),
      },
    },
  };
};

export const scatterChartData = (data) => {
  const seriesData = Object.entries(data)
    .filter(
      ([_, values]) => values.tokensIn !== null && values.tokensOut !== null
    )
    .map(([threadId, values]) => [values.tokensIn, values.tokensOut, threadId]);

  const series = [
    {
      name: "Tokens Data",
      data: seriesData,
    },
  ];

  return series;
};

export const scatterChartOptions = (
  textColor,
  xAxisTitle,
  yAxisTitle,
  showToolbar
) => {
  return {
    chart: {
      type: "scatter",
      zoom: {
        enabled: false,
        type: "xy",
      },
      toolbar: {
        show: showToolbar,
        offsetX: 0,
        offsetY: -40,
      },
    },

    xaxis: {
      labels: {
        style: textStyle(textColor),
      },
      tickAmount: 10,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      title: {
        text: xAxisTitle ?? "",
        style: {
          color: textColor,
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        style: textStyle(textColor, "14px"),
      },
      title: {
        text: yAxisTitle ?? "",
        style: {
          color: textColor,
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
      tickAmount: 7,
    },
    tooltip: {
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        const x = w.globals.seriesX[seriesIndex][dataPointIndex];
        const y = w.globals.series[seriesIndex][dataPointIndex];
        const z = w.globals.seriesZ[seriesIndex][dataPointIndex];

        return `<div style='padding:20px'>
                  <span>Thread ID: ${z}</span><br>
                  <span>Tokens In: ${x}</span><br>
                  <span>Tokens Out: ${y}</span>
                </div>`;
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF", "#39B8FF"],
        opacity: 0.5,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 40,
        left: 0,
      },
    },
    color: ["#7551FF", "#39B8FF"],
  };
};
