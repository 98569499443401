const BE_API = `${process.env.REACT_APP_BE_URL}/api`
export async function createAssistant(assistantData) {
    const url = `${BE_API}/assistants`;
  
    const requestBody = {
      assistant: {
        id: assistantData.assistant.id,
        name: assistantData.assistant.name, 
        description: assistantData.assistant.description,
        url: assistantData.assistant.url,
        logo_url: assistantData.assistant.logo_url, 
        type: assistantData.assistant.type, 
        widget_code: assistantData.assistant.widget_code,
        website_url: assistantData.assistant.website_url,
        widget_icon_url: assistantData.assistant.widget_icon_url,
      },
      apiKey: {
        api_key: assistantData.apiKey.api_key,
        openai_organization_id: assistantData.apiKey.openai_organization_id, 
        openai_project_id: assistantData.apiKey.openai_project_id,
      },
      admin: {
        admin_id: assistantData.adminId,
        active: true,
      },
    };
  
    try {
      console.log('Payload being sent:', requestBody);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const result = await response.json();
      console.log('Assistant created successfully:', result);
      return result;
    } catch (error) {
      console.error('Failed to create assistant:', error);
      // Handle the error appropriately in your UI
    }
  }