import {
    Button, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent,
    ModalFooter, ModalHeader, ModalOverlay, useColorModeValue
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { updateAdmin } from '../api/users';

const EditUser = ({ isOpen, onClose, item, onUpdate, assistantId }) => {
    const cardBackground = useColorModeValue("white", "#323232");
    console.log('item', item)
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');    
    useEffect(() => {
        if (item) {
            setName(item.name || '');
            setPhone(item.auth_users_profile?.phone || '');
        }
    }, [item]);

    const handleSave = async () => {
        try {
            const updatedItem = await updateAdmin(assistantId?.id, item.auth_users_profile_id, name, phone);
            if (updatedItem) {
                const fullUpdatedItem = {
                    ...item,
                    name: name !== '' ? name : item.name,
                    phone: phone !== '' ? phone :item.auth_users_profile.phone
                };
                onUpdate(fullUpdatedItem, 'update');
                onClose();
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay bg="blackAlpha.300" backdropFilter='blur(10px) hue-rotate(90deg)' />
            <ModalContent bg={cardBackground}>
                <ModalHeader>Edit User</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormLabel>Name</FormLabel>
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        mb={3}
                    />
                    <FormLabel>Email</FormLabel>
                    <Input
                        value={item?.auth_users_profile?.email}
                        placeholder="Email"
                        mb={3}
                        disabled
                    />
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Please enter your phone number"
                        type="tel"
                        mb={3}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="orange" bg="#E56B20" color="white" mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button variant="ghost" onClick={handleSave}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default EditUser;
