import { supabase } from "superbase/auth";

export const getAssistantThreads = async (assistantId, limit) => {
  const { data: threads, error: threadError } = await supabase
    .from("threads")
    .select("*")
    .eq("assistant_id", assistantId)
    .order("created_at", { ascending: false })
    .limit(limit);
  return { threads, threadError };
};

export async function getAdminAssistants(userId, threadLimit = 5) {
  const { data, error } = await supabase
    .from("admins")
    .select(
      `
      *,
      assistant_admins(
        *,
        assistants(*)
      )
    `
    )
    .eq("auth_users_profile_id", userId)
    .single();

  if (data && data.assistant_admins) {
    const assistantAdmins = {
      assistants: await Promise.all(
        data.assistant_admins
          .map((admin) => admin.assistants)
          .reduce((acc, curr) => acc.concat(curr), [])
          .map(async (assistant) => {
            const { threads, threadError } = await getAssistantThreads(
              assistant.id
            );

            if (threadError) {
              console.error(
                `Error fetching threads for assistant ${assistant.id}:`,
                threadError
              );
              return { ...assistant, threads: [] };
            }

            return { ...assistant, threads };
          })
      ),
    };
    data.assistant_admins = assistantAdmins;
  }

  return { data, error };
}
