import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Calendar from "components/calendar";
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import Loader from "components/loader/Loader";
import { pieChartData, pieChartOptions } from "constants/charts";
import React from "react";
import ChartTooltip from "tooltip/ChartTooltip";

const PieCard = (props) => {
  const {
    data,
    isLoading,
    colors,
    details,
    type,
    title,
    showToolbar,
    filterKey,
    setFilterDates,
    showCalendar,
    ...rest
  } = props;
  const heading = useColorModeValue("#000", "secondaryGray.300");
  const cardBg = useColorModeValue("white", "#323232");
  const isDarkMode = useColorModeValue(false, true);

  return (
    <Card p="20px" bg={cardBg} direction="column" w="100%" h="450px" {...rest}>
      <Flex w="100%" alignItems="center" gap={2}>
        <Text color={heading} fontSize="22px" fontWeight="700">
          {title}
        </Text>
        <ChartTooltip details={details} />
        {showCalendar && (
          <Calendar
            selectRange={true}
            setFilterDates={setFilterDates}
            filterKey={filterKey}
          />
        )}
      </Flex>
      <Box height="100%">
        {isLoading || !data ? (
          <Loader />
        ) : (
          <PieChart
            h="100%"
            w="100%"
            type={type}
            chartData={pieChartData(data)}
            chartOptions={pieChartOptions(
              data,
              colors,
              showToolbar,
              isDarkMode
            )}
          />
        )}
      </Box>
    </Card>
  );
};

export default PieCard;
