import { format } from "date-fns";
import { json2csv } from "json-2-csv";
import { truncateString } from "utils/string";

export const convertToCSV = (
  jsonData,
  fileName,
  fieldsToExclude = [],
  keyToTruncate = "conversation",
  maxLength = 100
) => {
  const truncatedData = jsonData.map((item) => {
    let newItem = { ...item };

    if (newItem[keyToTruncate]) {
      newItem[keyToTruncate] = truncateString(
        newItem[keyToTruncate],
        maxLength
      );
    }

    fieldsToExclude.forEach((field) => {
      delete newItem[field];
    });

    return newItem;
  });

  const options = {
    delimiter: {
      field: ",", // field delimiter
      wrap: '"', // wrap each field with double quotes
    },
  };

  const csv = json2csv(truncatedData, options);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);

  link.setAttribute("href", url);
  link.setAttribute(
    "download",
    `${fileName}-${format(new Date(), "dd-MM-yyyy")}.csv`
  );
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
