import { AspectRatio, Box, Button, Flex, FormControl, FormLabel, Input, useColorModeValue, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { getStreamingAvatar, updateStreamingAvatar } from '../api/streaming';

const EditStreaming = () => {
    const { id } = useParams();
    const [voiceId, setVoiceId] = useState('');
    const [posterUrl, setPosterUrl] = useState('');
    const [avatarUrl, setAvatarUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const tableBackground = useColorModeValue("white", "#323232");
    const navigate = useNavigate();
    const assistantId = useSelector(
        (state) => state.admin.selectedAssistant
    );
    const toast = useToast();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const avatarData = await getStreamingAvatar(assistantId?.id, id);
                setVoiceId(avatarData.voice_id || '');
                setPosterUrl(avatarData.poster_url || '');
                setAvatarUrl(avatarData.avatar_id || '');
            } catch (error) {
                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchData();
    }, [assistantId?.id, id, toast]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const updatedData = {
            ...(voiceId && { voiceId: voiceId }),
            ...(posterUrl && { posterUrl: posterUrl }),
            ...(avatarUrl && { avatarId: avatarUrl }),
        };

        try {
            const updatedDataResponse = await updateStreamingAvatar(assistantId?.id, id, updatedData);
            if (updatedDataResponse && Array.isArray(updatedDataResponse) && updatedDataResponse.length === 0) {
                throw new Error("Empty response received. Please check the server-side implementation.");
            }

            toast({
                title: "Success",
                description: "Streaming avatar updated successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            navigate(-1);
        } catch (error) {
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }} position="relative">
        <Button variant='brand' bg='black' onClick={()=>navigate(-1)}>Back</Button>
        <Flex justifyContent='space-between' flexWrap='wrap' alignItems='center'gap={4}>
        <Box width={{ base: '100%', lg: '49%' }} as="form" onSubmit={handleSubmit}  >
            <Box p={4} border={1} borderRadius={10} bg={tableBackground}>
                <FormControl id="voiceId" mb={4}>
                    <FormLabel>Voice ID</FormLabel>
                    <Input
                        type="text"
                        value={voiceId}
                        onChange={(e) => setVoiceId(e.target.value)}
                        placeholder="Enter voice ID"
                    />
                </FormControl>
                <FormControl id="avatarId" mb={4}>
                    <FormLabel>Avatar ID</FormLabel>
                    <Input
                        type="text"
                        value={avatarUrl}
                        onChange={(e) => setAvatarUrl(e.target.value)}
                        placeholder="Enter avatar ID"
                    />
                </FormControl>
                <FormControl id="posterUrl" mb={4}>
                    <FormLabel>Poster URL</FormLabel>
                    <Input
                        type="text"
                        value={posterUrl}
                        onChange={(e) => setPosterUrl(e.target.value)}
                        placeholder="Enter poster URL"
                    />
                </FormControl>
                
                <Button type="submit" colorScheme="orange" bg='#E56B20' color='white' isLoading={loading}>
                    Update Avatar
                </Button>
                <Button ml={3} variant='ghost' onClick={() => navigate(-1)}>Cancel</Button>
            </Box>
        </Box>
        <Box width={{ base: '100%', lg: '49%' }}>
                <AspectRatio maxW="800px" ratio={1}>
                    <iframe
                        width="100%"
                        height="100%"
                        src={`https://app.askmeai.co/chats/streaming-avatar/${assistantId?.url}`}
                        title="Streaming Avatar"
                        style={{ border: '1px solid black' }}
                    />
                </AspectRatio>
            </Box>
        </Flex>
        </Box>
    )
}

export default EditStreaming