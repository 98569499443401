import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import adminReducer from "./slices/admin";
import { threadsApi } from "store/api/threads";

const rootReducer = combineReducers({
  [threadsApi.reducerPath]: threadsApi.reducer,
  admin: adminReducer,
});

export const setupStore = (preloadedState) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(threadsApi.middleware),
  });
  return store;
};

export const store = setupStore();

setupListeners(store.dispatch);
