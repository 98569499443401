const BE_API = `${process.env.REACT_APP_BE_URL}/api`

export async function getStreamingAvatars(assistantId) {
    const response = await fetch(`${BE_API}/assistants/${assistantId}/streaming-avatars`);
    if (response.ok) {
        const avatars = await response.json();
        return avatars;
    } else {
        throw new Error('Failed to fetch streaming avatars');
    }
}

export async function createStreamingAvatar(assistantId, avatarData) {
    console.log('Payload being sent:', avatarData);

    const response = await fetch(`${BE_API}/assistants/${assistantId}/streaming-avatars`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(avatarData)
    });

    if (response.ok) {
        const newAvatar = await response.json();
        console.log('Received new avatar:', newAvatar);
        return newAvatar;
    } else {
        const errorResponse = await response.json();
        console.error('Error creating streaming avatar:', errorResponse);
        throw new Error('Failed to create streaming avatar');
    }
}


export async function getStreamingAvatar(assistantId, id) {
    const response = await fetch(`${BE_API}/assistants/${assistantId}/streaming-avatars/${id}`);
    if (response.ok) {
        const avatar = await response.json();
        return avatar;
    } else {
        throw new Error('Failed to fetch streaming avatar');
    }
}

export async function updateStreamingAvatar(assistantId, id, updatedData) {
    const response = await fetch(`${BE_API}/assistants/${assistantId}/streaming-avatars/${id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedData)
    });
    if (response.ok) {
        const updatedAvatar = await response.json();
        return updatedAvatar;
    } else {
        throw new Error('Failed to update streaming avatar');
    }
}


export async function deleteStreamingAvatar(assistantId, id) {
    const response = await fetch(`${BE_API}/assistants/${assistantId}/streaming-avatars/${id}`, {
        method: 'DELETE'
    });
    if (response.ok) {
        const message = await response.text();
        return message;
    } else {
        throw new Error('Failed to delete streaming avatar');
    }
}



