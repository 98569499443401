import { Box, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getStreamingAvatars } from './api/streaming';
import Loader from 'components/loader/Loader';
import StreamingTable from './components/StreamingTable';
import AddNewStreaming from './components/AddNewStreaming';

const Streaming = () => {
    const [streaming, setStreaming] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const assistantId = useSelector(
        (state) => state.admin.selectedAssistant
    );

    const handleOpen = () => setIsModalOpen(true);
    const handleClose = () => setIsModalOpen(false);

    useEffect(() => {
        const fetchStreaming = async () => {
            try {
                setLoading(true);
                const response = await getStreamingAvatars(assistantId?.id);
                if (response) {
                    setStreaming(response);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchStreaming();
    }, [assistantId]);

    const handleUpdate = (item, operationType) => {
        setStreaming((prevStream) => {
            let updatedStream;

            if (operationType === 'update') {
                updatedStream = prevStream.map((prevItem) =>
                    prevItem.id === item.id ? { ...prevItem, ...item } : prevItem
                );
            } else if (operationType === 'delete') {
                updatedStream = prevStream.filter((prevItem) => prevItem.id !== item.id);
            } else if (operationType === 'create') {
                if (!item.created_at) {
                    item.created_at = new Date().toISOString();
                }
                updatedStream = [...prevStream, ...item];
            }

            return updatedStream;
        });
    };

    const handleAdd = (newItem) => {
        handleUpdate(newItem, 'create');
    };

    return (
        <>
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }} position="relative" minHeight="90vh">
                <Box display='flex' justifyContent='end'>
                    <Button variant="brand" fontWeight="500" marginLeft="auto" width="max-content" minW={100} onClick={handleOpen}>Add New</Button>
                </Box>
                {loading ? (<Loader />) : (
                    <StreamingTable
                        data={streaming}
                        assistantId={assistantId}
                        onUpdate={handleUpdate}
                    />
                )}
            </Box>
            <AddNewStreaming isOpen={isModalOpen} onClose={handleClose} assistantId={assistantId} onAdd={handleAdd} />
        </>
    );
};

export default Streaming;
