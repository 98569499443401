import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import { createAdmin } from "../api/users";

const AddNewUser = ({ isOpen, onClose, onAdd, assistantId }) => {
  const cardBackground = useColorModeValue("white", "#323232");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password } = formData;
    console.log('form data', formData);

    const result = await createAdmin(assistantId?.id, name, email, password);
    console.log(result);
    if (result) {
      onAdd(result);
      setFormData({
        name: "",
        email: "",
        password: "",
      });
      onClose();
    } else {
      console.error("Failed to create admin");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent bg={cardBackground}>
        <ModalHeader>Add New User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl pb={2} as="form" onSubmit={handleSubmit}>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input
              id="name"
              name="name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              placeholder="Please enter your name..."
            />
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Please enter your email..."
            />
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              id="password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Please enter your password..."
            />
            <Button
              mt={4}
              colorScheme="orange"
              bg="#E56B20"
              color="white"
              type="submit"
            >
              Create
            </Button>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddNewUser;
