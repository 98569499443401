import React from "react";
import { Box } from "@chakra-ui/react";
import { keyframes } from "@chakra-ui/react";

const loadingWaveAnimation = keyframes`
  0% { height: 10px; }
  50% { height: 50px; }
  100% { height: 10px; }
`;

const Loader = () => {
  const bgColor = "assistantColor.500";

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {[0, 1, 2, 3].map((_, index) => (
        <Box
          key={index}
          width="20px"
          height="10px"
          margin="0 5px"
          backgroundColor={bgColor}
          borderRadius="5px"
          animation={`${loadingWaveAnimation} 1s ease-in-out infinite`}
          className="loading-bar"
        />
      ))}
    </Box>
  );
};

export default Loader;
