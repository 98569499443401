import { Box, Button } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';


import AddNew from './components/AddNew';
import AnnounceTable from './components/AnnounceTable';
import { getAllAnnouncement } from './api/announce';
import Loader from 'components/loader/Loader';

const Announcement = () => {
    const [announcement, setAnnouncement] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true)
    const handleOpen = () => setIsModalOpen(true);
    const handleClose = () => setIsModalOpen(false);
    const assistantId = useSelector(
        (state) => state.admin.selectedAssistant
    );

    useEffect(() => {
        const fetchAnnouncement = async () => {
            try {
                setLoading(true)
                const response = await getAllAnnouncement(assistantId?.id)
                if (response) {
                    // Sort announcements by 'created_at' in descending order (most recent first)
                    const sortedAnnouncements = response.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    setAnnouncement(sortedAnnouncements);
                }
                // console.log(response)
                if (response) {
                    setAnnouncement(response)
                }
            }
            catch (error) {
                console.error('Error fetching announcements:', error);
            }finally{
                setLoading(false)
            }
        }
        fetchAnnouncement()

    }, [assistantId])
    const handleUpdate = (item, operationType) => {
        setAnnouncement((prevAnnouncement) => {
            let updatedAnnouncement;
    
            if (operationType === 'update') {
                updatedAnnouncement = prevAnnouncement.map((prevItem) =>
                    prevItem.id === item.id ? { ...prevItem, title: item.title, description: item.description, long_description: item.long_description } : prevItem
                );
            } else if (operationType === 'delete') {
                updatedAnnouncement = prevAnnouncement.filter((prevItem) => prevItem.id !== item.id);
            } else if (operationType === 'create') {
                updatedAnnouncement = [...prevAnnouncement, item];
            }
    
            // console.log('Updated Announcement:', updatedAnnouncement);
            return updatedAnnouncement;
        });
    };
    

    const handleAdd = (newItem) => {
        handleUpdate(newItem, 'create');
    };
    return (
        <>
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }} position="relative">
                <Box display='flex' justifyContent='end'>
                    <Button variant="brand" fontWeight="500" marginLeft="auto" width="max-content" minW={100} onClick={handleOpen}>Add New</Button>
                </Box>
                {loading ? (<Loader/>):(
                    <AnnounceTable response={announcement} onUpdate={handleUpdate} assistantId={assistantId} />
                )}

            </Box>
            <AddNew isOpen={isModalOpen} onClose={handleClose} assistantId={assistantId} onAdd={handleAdd} />
        </>
    )
}

export default Announcement