import React, { useState } from 'react';
import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useColorModeValue } from '@chakra-ui/react';
import { createSuggestedPrompt } from '../api/prompt';

const NewPopupForm = ({ isOpen, onClose, assistantId, onAdd }) => {
    const cardBackground = useColorModeValue("white", "#323232");
    const [formData, setFormData] = useState({ suggestion: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await createSuggestedPrompt(assistantId, formData.suggestion);
            if (response && response.length > 0) {
                const newItem = {
                    ...response[0],
                    created_at: new Date(response[0].created_at).toISOString()
                };
                onAdd(newItem);
                console.log('New item added:', newItem);
            }
            setFormData({ suggestion: '' });
            onClose();
        } catch (error) {
            console.error('Error creating suggestion:', error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay bg="blackAlpha.300" backdropFilter='blur(10px) hue-rotate(90deg)'/>
            <ModalContent bg={cardBackground}>
                <ModalHeader>Add New Suggestion</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl pb={2} as="form" onSubmit={handleSubmit}>
                        <FormLabel htmlFor="suggestion">Suggestion</FormLabel>
                        <Input
                            id="suggestion"
                            name="suggestion"
                            value={formData.suggestion}
                            placeholder='Please enter your suggested prompt...'
                            onChange={handleChange}
                        />
                        <Button mt={4} colorScheme="orange" bg="#E56B20" color="white" type="submit">Create</Button>
                    </FormControl>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default NewPopupForm;
