import { Box, keyframes } from '@chakra-ui/react'
import React from 'react'

const ChatLoader = () => {
    const gradientAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;
  return (
    <Box
      position="relative"
      width="540px"
      height="130px"
      marginBottom="10px"
      padding="15px"
      overflow="hidden"
    >
      <Box
        position="absolute"
        width="100%"
        height="100%"
        top="0"
        left="0"
        background="linear-gradient(110deg, #3c3c3c00 0%, #3c3c3c25 40%, #3c3c3c 50%, #3c3c3c25 60%, #3c3c3c00 100%)"
        animation={`${gradientAnimation} 1.2s linear infinite`}
      />
      <Box
        position="relative"
        width="100%"
        height="100%"
      >
        <Box
          position="absolute"
          top="11px"
          height="10px"
          width="100%"
          backgroundColor="#cacaca"
        />
        <Box
          position="absolute"
          top="34px"
          height="10px"
          width="150px"
          backgroundColor="#cacaca"
        />
        <Box
          position="absolute"
          top="57px"
          left="0px"
          height="10px"
          width="92%"
          backgroundColor="#cacaca"
        />
        <Box
          position="absolute"
          top="80px"
          left="0px"
          height="10px"
          width="100%"
          backgroundColor="#cacaca"
        />
      </Box>
    </Box>
  )
}

export default ChatLoader