import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  useColorModeValue,
  Fade,
  Flex,
} from "@chakra-ui/react";
import Loader from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { getAllAnnouncement } from "views/admin/announcement/api/announce";

const AnnouncementCard = ({ assistantId }) => {
  const cardBackground = useColorModeValue("#fff", "#323232");
  const cardItemBg = useColorModeValue("#fff", "#3c3c3c");
  const [announcements, setAnnouncements] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate() 

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await getAllAnnouncement(assistantId);
        console.log(response)
        if (response) {
          const sortedAnnouncements = response.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setAnnouncements(sortedAnnouncements);
        }
      } catch (error) {
        console.error("Error fetching announcements:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAnnouncement();
  }, [assistantId]);

  const handleNext = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        Math.min(prevIndex + 2, announcements.length)
      );
      setFadeIn(true);
    }, 300);
  };

  const handlePrevious = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 2, 0));
      setFadeIn(true);
    }, 300);
  };

  const isNextDisabled = currentIndex >= announcements.length - 2;
  const isPreviousDisabled = currentIndex <= 0;
  const handleLink = (item) =>{
    console.log(item);
    navigate(`/announcement/${item.id}`);
  }

  return (
    <Card bg={cardBackground} borderRadius={20}>
      <CardHeader>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Heading size="md" fontSize="22px" fontWeight="700">
            Recent Announcements
          </Heading>
          <Box display="flex" gap={3}>
            <Button
              p={2}
              borderRadius="50%"
              onClick={handlePrevious}
              isDisabled={isPreviousDisabled}
              variant="outline"
            >
              <MdArrowBack size={25} color="#E56B20" />
            </Button>
            <Button
              p={2}
              borderRadius="50%"
              onClick={handleNext}
              isDisabled={isNextDisabled}
              variant="outline"
            >
              <MdArrowForward size={25} color="#E56B20" />
            </Button>
          </Box>
        </Box>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Loader />
        ) : (
          <Fade
            in={fadeIn}
            transition={{ enter: { duration: 0.3 }, exit: { duration: 0.3 } }}
          >
            <Flex direction="column" gap={4}>
              {announcements
                .slice(currentIndex, currentIndex + 2)
                .map((item) => (
                  <Card key={item.id} bg={cardItemBg} borderRadius={20} onClick={() => handleLink(item)} cursor='pointer'>
                    <CardHeader
                      fontSize="20px"
                      color="#E56B20"
                      fontWeight={700}
                      pb={1}
                     >
                      {item.title}
                    
                    </CardHeader>
                    <CardBody>{item.description}</CardBody>
                  </Card>
                ))}
            </Flex>
          </Fade>
        )}
      </CardBody>
      <CardFooter>
        <Button variant="brand">
          <Link to="/announcement">View All</Link>
        </Button>
      </CardFooter>
    </Card>
  );
};

export default AnnouncementCard;
