import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import Loader from "components/loader/Loader";
import { format } from "date-fns";
import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetAssistantThreadByIdQuery } from "store/api/threads";

const ThreadDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const background = useColorModeValue("#fff", "#323232");
  const textColor = useColorModeValue("#000", "#e2e8f0");
  const tooltipBg = useColorModeValue("#000", "#434343");
  const tooltipColor = useColorModeValue("#ffffff", "#fff");

  const selectedAssistantId = useSelector(
    (state) => state.admin.selectedAssistantId
  );

  const { data: threadDetails, isLoading } = useGetAssistantThreadByIdQuery(
    {
      assistantId: selectedAssistantId,
      threadId: id,
    },
    {
      skip: !id || !selectedAssistantId,
    }
  );

  const renderConversation = (text) => {
    const htmlString = text.replace(/\n/g, "<br />");
    return { __html: htmlString };
  };

  let creatorDetailsContent;

  try {
    const creatorDetails = threadDetails?.creator_details;
    if (creatorDetails && typeof creatorDetails === "object") {
      creatorDetailsContent = Object.entries(creatorDetails).map(
        ([key, value]) => (
          <Box key={key} display="flex" mb={1} gap={1}>
            <Text fontWeight="bold" color={textColor}>
              {key}:
            </Text>
            <Text color={textColor}>{value}</Text>
          </Box>
        )
      );
    } else {
      creatorDetailsContent = <Text color={textColor}>No creator details</Text>;
    }
  } catch (error) {
    creatorDetailsContent = <Text color={textColor}>No creator details</Text>;
  }
  const formatDate = (isoString) => {
    if (!isoString) {
      return "Invalid date";
    }
    return format(new Date(isoString), "MM/dd/yyyy, h:mm:ss a");
  };

  return (
    <Card
      position="relative"
      top={{ base: "120px", md: "80px" }}
      bg={background}
      p={3}
      boxShadow="md"
      borderRadius={10}
      overflowY="auto"
      maxHeight="80vh"
    >
      <Button
        position="absolute"
        top={0}
        left={0}
        borderRadius="10px 0 32px 0"
        variant="brand"
        onClick={() => navigate(-1)}
        mb={4}
      >
        <Tooltip
          label="Go Back"
          placement="top"
          mb={2}
          bg={tooltipBg}
          color={tooltipColor}
        >
          <Text>
            <IoIosArrowBack size={20} />
          </Text>
        </Tooltip>
      </Button>
      {!isLoading ? (
        <Box mt={10}>
          <Heading size="lg" color={textColor}>
            Details for {threadDetails?.first_message}
          </Heading>
          <Flex
            gap={3}
            flexDirection={{ base: "column", md: "row", lg: "row" }}
          >
            <Box
              p={5}
              maxWidth="2xl"
              width="100%"
              color={textColor}
              className="rich-text"
              dangerouslySetInnerHTML={renderConversation(
                threadDetails?.conversation || ""
              )}
              overflow="hidden"
            />
            <Box
              p={5}
              borderTop={{
                base: `1px solid ${
                  background === "#fff" ? "#c7c7c7" : "#4a5568"
                }`,
                md: "none",
              }}
              borderLeft={{
                base: "none",
                md: `1px solid ${
                  background === "#fff" ? "#c7c7c7" : "#4a5568"
                }`,
              }}
              overflow="hidden"
            >
              <Box display="flex" mb={2} gap={1} flexWrap="wrap">
                <Text fontWeight="bold" color={textColor}>
                  Custom Name:
                </Text>
                <Text color={textColor}>
                  {threadDetails?.custom_name || "No custom name"}
                </Text>
              </Box>
              <Box display="flex" mb={2} gap={1} flexWrap="wrap">
                <Text fontWeight="bold" color={textColor}>
                  User Persona:
                </Text>
                <Text color={textColor}>{threadDetails?.user_persona}</Text>
              </Box>
              <Box display="flex" mb={2} gap={1} flexWrap="wrap">
                <Text fontWeight="bold" color={textColor}>
                  User ID:
                </Text>
                <Text color={textColor}>{threadDetails?.user_id}</Text>
              </Box>
              <Box display="flex" mb={2} gap={1} flexWrap="wrap">
                <Text fontWeight="bold" color={textColor}>
                  Duration:
                </Text>
                <Text color={textColor}>{threadDetails?.duration}</Text>
              </Box>
              <Box display="flex" mb={2} gap={1} flexWrap="wrap">
                <Text fontWeight="bold" color={textColor}>
                  ID:
                </Text>
                <Text color={textColor}>{threadDetails?.id}</Text>
              </Box>
              <Box display="flex" mb={2} gap={1} flexWrap="wrap">
                <Text fontWeight="bold" color={textColor}>
                  Thread Url:
                </Text>
                <Box
                  as={Link}
                  to={threadDetails?.thread_url}
                  target="_blank"
                  color={textColor}
                  maxWidth="250px"
                  isTruncated
                >
                  {threadDetails?.thread_url}
                </Box>
              </Box>
              <Box display="flex" mb={2} gap={1} flexWrap="wrap">
                <Text fontWeight="bold" color={textColor}>
                  Created At:
                </Text>
                <Text color={textColor}>
                  {threadDetails?.created_at
                    ? formatDate(threadDetails.created_at)
                    : "N/A"}
                </Text>
              </Box>
              <Box display="flex" mb={2} gap={1} flexWrap="wrap">
                <Text fontWeight="bold" color={textColor}>
                  Updated At:
                </Text>
                <Text color={textColor}>
                  {threadDetails?.updated_at
                    ? formatDate(threadDetails.updated_at)
                    : "N/A"}
                </Text>
              </Box>
              <Box display="flex" mb={2} gap={1} flexWrap="wrap">
                <Text fontWeight="bold" color={textColor}>
                  Assistant ID:
                </Text>
                <Text color={textColor}>
                  {threadDetails?.assistant_id || "No Assistant ID"}
                </Text>
              </Box>
              <Flex
                alignItems="center"
                mb={2}
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Box>
                  <Text fontWeight="bold" color={textColor}>
                    Token In
                  </Text>
                  <Text color={textColor}>{threadDetails?.tokens_in}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color={textColor}>
                    Token Out
                  </Text>
                  <Text color={textColor}>{threadDetails?.tokens_out}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color={textColor}>
                    Total Tokens
                  </Text>
                  <Text color={textColor}>{threadDetails?.total_tokens}</Text>
                </Box>
              </Flex>
              <Box display="flex" mb={2} gap={1} flexWrap="wrap">
                <Text fontWeight="bold" color={textColor}>
                  Time of Use:
                </Text>
                <Text color={textColor}>{threadDetails?.time_of_use}</Text>
              </Box>
              <Box
                display="flex"
                mb={2}
                gap={1}
                flexDirection="column"
                flexWrap="wrap"
              >
                <Text fontWeight="bold" color={textColor}>
                  Creator Details:
                </Text>
                {creatorDetailsContent}
              </Box>
            </Box>
          </Flex>
        </Box>
      ) : (
        <Box h="40vh">
          <Loader />
        </Box>
      )}
    </Card>
  );
};

export default ThreadDetails;
