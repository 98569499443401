import {
  Portal,
  Box,
  useDisclosure,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
// import { useColorModeValue } from "@chakra-ui/system";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import Navbar from "components/navbar/AdminNavbar";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { sidebarLinks as routes } from "routes/urls";
import {
  saveAdminDetails,
  saveAccountDetails,
  setSelectedAssistantId,
} from "store/slices/admin";
import { getAdminAssistants } from "superbase/apis";
import { supabase } from "superbase/auth";

const AdminLayout = (props) => {
  const themeBgColor = useColorModeValue("#f5f5f5", "#434343");
  const dispatch = useDispatch();
  const { ...rest } = props;
  const toast = useToast();
  const location = useLocation();
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const handleGetAdminDetails = useCallback(async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (user) {
      const { data: adminData, error: adminError } = await getAdminAssistants(
        user?.id
      );
      if (adminError) {
        toast({
          title: adminError.message || "Failed to fetch assistants",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        dispatch(saveAdminDetails(adminData));
        dispatch(saveAccountDetails(user));
        dispatch(
          setSelectedAssistantId(adminData?.assistant_admins?.assistants[0]?.id)
        );
      }
    }
  }, [toast, dispatch]);

  useEffect(() => {
    handleGetAdminDetails();
  }, [handleGetAdminDetails]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Default";
    let path = location.pathname;
    if(path.slice(0,11) === '/assistants'){
      activeRoute = "Add New Assistants"
    }else{
      activeRoute = "Default";
    }
    console.log(path.slice(0,11));
    
    for (let i = 0; i < routes.length; i++) {
      if (path === routes[i].path || path.slice(0,13) === routes[i].path || path.slice(0,17) === routes[i].path || path.slice(0,11) === routes[i].path) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].messageNavbar;
      }
    }
    return activeNavbar;
  };

  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  document.documentElement.dir = "ltr";
  return (
    <Box>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar routes={routes} display="none" {...rest} />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="hidden"
            position="relative"
            maxHeight="100%"
            w={{ base: "100%", xl: "calc( 100% - 300px )" }}
            maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
            bgColor={themeBgColor}
            pt={5}
          >
            <Portal>
              <Box position='fixed' top={5} w='100%'>
                <Navbar
                  onOpen={onOpen}
                  logoText={"Askme AI"}
                  brandText={getActiveRoute(routes)}
                  secondary={getActiveNavbar(routes)}
                  message={getActiveNavbarText(routes)}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal>
            <Box
              mx="auto"
              bg={themeBgColor}
              p={{ base: "20px", md: "30px" }}
              pe="20px"
              // minH="100vh"
              // maxWidth='1700px'
            >
              <Outlet />
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
      <FixedPlugin />
    </Box>
  );
};

export default AdminLayout;
