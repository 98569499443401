import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";

const Banner = (props) => {
  const { avatar, name, job, assistants, totalThreads } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardBg = useColorModeValue("white", "#323232");

  return (
    <Card mb={{ base: "0px", lg: "20px" }} bg={cardBg}>
      <Box
        bg="linear-gradient(
          to bottom,
          rgba(237, 89, 27, 1) 0%,
          rgba(237, 89, 27, 0.5) 100%
        )"
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />
      <Avatar
        mx="auto"
        src={avatar}
        h="87px"
        w="87px"
        mt="-43px"
        border="4px solid"
      />
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="xl"
        mt="10px"
        textAlign="center"
      >
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize="sm" textAlign="center">
        {job}
      </Text>
      <Flex w="max-content" direction="row" mx="auto" mt="26px" gap={8}>
        <Flex mx="auto" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {assistants?.length}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Assistants
          </Text>
        </Flex>
        <Flex mx="auto" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {totalThreads}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Total Threads
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

export default Banner;
