import { Button, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { updateAnnouncement } from '../api/announce';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

const EditAnnouncement = ({ isOpen, onClose, item, onUpdate }) => {
    const cardBackground = useColorModeValue("white", "#323232");
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [longDescription, setLongDescription] = useState('');

    useEffect(() => {
        if (item) {
            setTitle(item.title || '');
            setDescription(item.description || '');
            setLongDescription(item.long_description || '');
        }
    }, [item]);

    const handleSave = async () => {
        if (item) {
            try {
                // console.log('Long description:', longDescription);
                const updatedResponse = await updateAnnouncement(item.assistant_id, item.id, title, description, longDescription);
                const updatedItem = updatedResponse[0];
                if (updatedItem) {
                    // console.log(updatedItem)
                    onUpdate({ ...item, title: updatedItem.title, description: updatedItem.description, long_description: updatedItem.long_description }, 'update');
                } else {
                    console.error('No updated item returned');
                }
            } catch (error) {
                console.error('Error updating announcement:', error);
            }
            onClose();
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay bg="blackAlpha.300" backdropFilter='blur(10px) hue-rotate(90deg)' />
            <ModalContent bg={cardBackground} maxWidth='4xl' width='100%'>
                <ModalHeader>Edit Announcement</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormLabel htmlFor="title">Title</FormLabel>
                    <Input
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Edit your announcement title..."
                    />
                    <FormLabel mt={3} htmlFor="description">Description</FormLabel>
                    <Input
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Edit your announcement description..."
                    />
                    <FormLabel mt={3} htmlFor="longDescription">Long Description</FormLabel>
                    <ReactQuill 
                        id="longDescription"
                        value={longDescription}
                        onChange={setLongDescription}
                        placeholder="Edit long description..."
                        style={{ height: '150px' }}
                    />
                </ModalBody>
                <ModalFooter mt={6}>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button variant="brand" colorScheme="orange" bg="#E56B20" color="white" onClick={handleSave}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default EditAnnouncement;
