import { Navigate, Outlet } from "react-router-dom";
import { useJwt } from "react-jwt";
import { getAuthToken } from "utils/auth";
import AdminLayout from "layouts/admin";
import React from "react";

const ProtectedRoute = () => {
  const { isExpired } = useJwt(getAuthToken() ?? "");
  if (!getAuthToken() || isExpired) return <Navigate to="/sign-in" />;
  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  );
};

export default ProtectedRoute;
