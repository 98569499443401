import { Flex } from '@chakra-ui/react'
import React from 'react'
import Laptop from './Laptop'
import Mobile from './Mobile'

const LivePreview = ({selectedAssistantName}) => {
  return (
    <Flex gap="5px" justifyContent="space-between" flexWrap="wrap">
      <Laptop url={selectedAssistantName?.website_url}/>
      <Mobile url={selectedAssistantName?.website_url}/>
    </Flex>
  )
}

export default LivePreview