import React from 'react'
import { useLocation } from 'react-router-dom';
import NewAssistant from './NewAssistant';
import ErrorPage from 'components/404/404Page';

const AssistantWrapper = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const AddNew = searchParams.get('add-new') === 'true';
  
    if (AddNew) {
      return <NewAssistant/>;
    }
  
    return <ErrorPage/>;
}

export default AssistantWrapper