import { Box, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import AddNewUser from './components/AddNewUser';
import UserTable from './components/UserTable';
import { getAllAdmins } from './api/users';
import { useSelector } from 'react-redux';
import Loader from 'components/loader/Loader';

const Users = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const assistantId = useSelector((state) => state.admin.selectedAssistant);

    const handleOpen = () => setIsModalOpen(true);
    const handleClose = () => setIsModalOpen(false);

    // console.log('Users data', user);
    

    useEffect(() => {
        let isMounted = true;
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await getAllAdmins(assistantId?.id);
                if (isMounted) {
                    setUsers(data);
                }
            } catch (error) {
                console.error('Error fetching users', error);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchUsers();
        return () => {
            isMounted = false;
        };
    }, [assistantId]);

    const handleUpdate = (item, operationType) => {
        setUsers((prevUsers) => {
            let updatedUsers = prevUsers;
    
            if (operationType === 'update') {
                updatedUsers = prevUsers.map((prevItem) => {
                    if (prevItem.auth_users_profile_id === item.auth_users_profile_id) {
                        return {
                            ...prevItem,
                            name: item.name !== undefined ? item.name : prevItem.name,
                            auth_users_profile: {
                                ...prevItem.auth_users_profile,
                                phone: item.phone !== undefined ? item.phone : prevItem.auth_users_profile.phone
                            }
                        };
                    }
                    return prevItem;
                });
            } else if (operationType === 'delete') {
                updatedUsers = prevUsers.filter((prevItem) => prevItem.id !== item.id);
            } else if (operationType === 'create') {
                updatedUsers = [...prevUsers, item];
            }
            return updatedUsers;
        });
    };    

    const handleAdd = (newItem) => {
        handleUpdate(newItem, 'create');
    };

    return (
        <>
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }} position="relative" minHeight="90vh">
                <Box display='flex' justifyContent='end'>
                    <Button variant="brand" fontWeight="500" marginLeft="auto" width="max-content" minW={100} onClick={handleOpen}>Add New</Button>
                </Box>
                {loading ? (
                    <Loader />
                ) : (
                    <UserTable
                        assistantId={assistantId}
                        setSelectedUser={setSelectedUser}
                        users={users}
                        onUpdate={handleUpdate}
                        setUsers={setUsers}
                        selectedUser={selectedUser}
                    />
                )}
            </Box>
            <AddNewUser isOpen={isModalOpen} onClose={handleClose} onAdd={handleAdd} assistantId={assistantId} />
        </>
    );
};

export default Users;
