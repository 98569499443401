import { Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const RowItem = ({ children, value }) => {
  const textColor = useColorModeValue("#000", "#fff");
  const toolTipBg = useColorModeValue('gray.300', '#434343');
  return (
    <Text
      fontSize="sm"
      fontWeight="700"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      width="100%"
    >
      <Tooltip label={value} placement="top" bg={toolTipBg} color={textColor} >
        <span>{children}</span>
      </Tooltip>
    </Text>
  );
};

export default RowItem;
