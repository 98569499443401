import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accountDetails: null,
  adminDetails: null,
  selectedAssistantId: null,
  selectedAssistant: null,
  totalThreads: 0,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    saveAccountDetails: (state, action) => {
      state.accountDetails = action.payload;
    },
    saveAdminDetails: (state, action) => {
      state.adminDetails = action.payload;

      const totalThreads =
        action.payload?.assistant_admins?.assistants?.reduce(
          (total, assistant) => total + (assistant?.threads?.length || 0),
          0
        ) || 0;

      state.totalThreads = totalThreads;
    },
    setSelectedAssistantId: (state, action) => {
      state.selectedAssistantId = action.payload;
      const assistant = state.adminDetails?.assistant_admins?.assistants.find(
        (assistant) => assistant.id === action.payload
      );
      state.selectedAssistant = assistant;
    },
  },
});

export const { saveAdminDetails, saveAccountDetails, setSelectedAssistantId } =
  adminSlice.actions;

export default adminSlice.reducer;
