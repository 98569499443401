import { Td, Tooltip, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';

const TruncatedCell = ({ text = '', length, formatter }) => {
  const displayText = formatter ? formatter(text) : text || '';
  const truncatedText = displayText.length > length ? `${displayText.slice(0, length)}...` : displayText;
  const tooltipBg = useColorModeValue('gray.300', '#434343');
  const tooltipColor = useColorModeValue('#000', '#fff');

  return (
    <Td px="20px" border="none" fontSize={12}>
      <Tooltip label={displayText} placement="top" bg={tooltipBg} color={tooltipColor}>
        <span>{truncatedText}</span>
      </Tooltip>
    </Td>
  );
};

TruncatedCell.propTypes = {
  text: PropTypes.string,
  length: PropTypes.number.isRequired,
  formatter: PropTypes.func,
};

export default TruncatedCell;
