import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import {
  BarCard,
  LineCard,
  PieCard,
  ScatterCard,
} from "components/customCards";
import { useSelector } from "react-redux";
import {
  useGetThreadCountByDayQuery,
  useGetThreadCountByHourQuery,
  useGetThreadsUserPersonasQuery,
  useGetThreadCountByDateQuery,
  useGetThreadsOverTimeQuery,
  useGetThreadsMessageCountDistributionQuery,
  useGetTokenUsageByDayQuery,
  useGetTokenUsageByHourQuery,
  useGetTokenUsageQuery,
  useGetThreadsTokenUsageQuery,
  // useGetThreadsDurationQuery,
} from "store/api/threads";
import Loader from "components/loader/Loader";
import Calendar from "components/calendar";

const Statistics = () => {
  const selectedAssistant = useSelector(
    (state) => state.admin.selectedAssistant
  );

  const [filterDates, setFilterDates] = useState({
    threadsPerDate: [null, null],
    threadsPerDay: [null, null],
    threadsPerHour: [null, null],
    threadsOverTime: [null, null],
    threadsTokenUsage: [null, null],
    threadsUserPersonas: [null, null],
    threadsDistribution: [null, null],
    tokenUsage: [null, null],
    tokenUsageByDay: [null, null],
    tokenUsageByHour: [null, null],
    all: [null, null],
  });
  console.log('filer date', filterDates)

  useEffect(() => {
    // Check if both start and end dates in filterDates.all are set
    if (filterDates.all[0] && filterDates.all[1]) {
      // Update the filterDates state
      setFilterDates((prevState) => {
        // Create a new object to store the updated filters
        const updatedFilters = {};

        // Iterate over each key in the previous state
        Object.keys(prevState).forEach((key) => {
          // Update each key's value to match the 'all' key value,
          // except for the 'all' key itself which remains unchanged
          updatedFilters[key] =
            key === "all" ? prevState.all : [...filterDates.all];
        });

        // Return the updated filters object to set the new state
        return updatedFilters;
      });
    }
  }, [filterDates.all]);

  const {
    data: threadsPerDay,
    isLoading: loadingPerDay,
    isFetching: fetchingPerDay,
  } = useGetThreadCountByDayQuery(
    {
      assistant: selectedAssistant?.id,
      startDate: filterDates.threadsPerDay[0],
      endDate: filterDates.threadsPerDay[1],
    },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const {
    data: threadsUserPersonas,
    isLoading: loadingThreadsUserPersonas,
    isFetching: fetchingThreadsUserPersonas,
  } = useGetThreadsUserPersonasQuery(
    {
      assistant: selectedAssistant?.id,
      // startDate: filterDates.threadsUserPersonas[0],
      // endDate: filterDates.threadsUserPersonas[1],
    },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const {
    data: threadsPerDate,
    isLoading: loadingPerDate,
    isFetching: fetchingPerDate,
  } = useGetThreadCountByDateQuery(
    {
      assistant: selectedAssistant?.id,
      startDate: filterDates.threadsPerDate[0],
      endDate: filterDates.threadsPerDate[1],
    },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const {
    data: threadsPerHour,
    isLoading: loadingPerHour,
    isFetching: fetchingPerHour,
  } = useGetThreadCountByHourQuery(
    {
      assistant: selectedAssistant?.id,
      startDate: filterDates.threadsPerHour[0],
      endDate: filterDates.threadsPerHour[1],
    },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const {
    data: threadsOverTime,
    isLoading: loadingOverTime,
    isFetching: fetchingOverTime,
  } = useGetThreadsOverTimeQuery(
    {
      assistant: selectedAssistant?.id,
      startDate: filterDates.threadsOverTime[0],
      endDate: filterDates.threadsOverTime[1],
    },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const {
    data: threadsDistribution,
    isLoading: loadingDistribution,
    isFetching: fetchingDistribution,
  } = useGetThreadsMessageCountDistributionQuery(
    {
      assistant: selectedAssistant?.id,
      startDate: filterDates.threadsDistribution[0],
      endDate: filterDates.threadsDistribution[1],
    },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const {
    data: tokenUsageByDay,
    isLoading: loadingTokenDay,
    isFetching: fetchingTokenDay,
  } = useGetTokenUsageByDayQuery(
    {
      assistant: selectedAssistant?.id,
      startDate: filterDates.tokenUsageByDay[0],
      endDate: filterDates.tokenUsageByDay[1],
    },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const {
    data: tokenUsageByHour,
    isLoading: loadingTokenHour,
    isFetching: fetchingTokenHour,
  } = useGetTokenUsageByHourQuery(
    {
      assistant: selectedAssistant?.id,
      startDate: filterDates.tokenUsageByHour[0],
      endDate: filterDates.tokenUsageByHour[1],
    },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const {
    data: tokenUsage,
    isLoading: loadingtokenUsage,
    isFetching: fetchingtokenUsage,
  } = useGetTokenUsageQuery(
    {
      assistant: selectedAssistant?.id,
      startDate: filterDates.tokenUsage[0],
      endDate: filterDates.tokenUsage[1],
    },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const {
    data: threadsTokenUsage,
    isLoading: loadingthreadsToken,
    isFetching: fetchingthreadsToken,
  } = useGetThreadsTokenUsageQuery(
    {
      assistant: selectedAssistant?.id,
      startDate: filterDates.threadsTokenUsage[0],
      endDate: filterDates.threadsTokenUsage[1],
    },
    {
      skip: !selectedAssistant?.id,
    }
  );

  // const {
  //   data: threadsDuration,
  //   isLoading: loadingthreadsDuration,
  //   isFetching: fetchingthreadsDuration,
  // } = useGetThreadsDurationQuery(
  //   { assistant: selectedAssistant?.id },
  //   {
  //     skip: !selectedAssistant?.id,
  //   }
  // );

  const tokenUsageData = useMemo(
    () => ({
      "Tokens Out": tokenUsage?.tokensOut,
      "Tokens In": tokenUsage?.tokensIn,
    }),
    [tokenUsage]
  );

  const threadsPerDateData = useMemo(
    () =>
      threadsPerDate?.data?.reduce((obj, item) => {
        obj[item.date] = item.count;
        return obj;
      }, {}),
    [threadsPerDate]
  );

  const threadsUserPersonasData = useMemo(
    () =>
      threadsUserPersonas?.reduce((obj, item) => {
        obj[item.user_persona] = item.count;
        return obj;
      }, {}),
    [threadsUserPersonas]
  );

  const threadsTokenUsageData = useMemo(
    () =>
      threadsTokenUsage?.reduce((acc, item) => {
        acc[item.threadId] = {
          tokensIn: item.tokensIn,
          tokensOut: item.tokensOut,
        };
        return acc;
      }, {}),
    [threadsTokenUsage]
  );

  // const threadsDurationData = useMemo(
  //   () =>
  //     threadsDuration?.reduce((acc, item) => {
  //       acc[item.threadId] = {
  //         tokensIn: item.tokensIn,
  //         tokensOut: item.tokensOut,
  //       };
  //       return acc;
  //     }, {}),
  //   [threadsDuration]
  // );

  if (!!selectedAssistant)
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex direction="row" justifyContent="flex-end" py={4} px={2} alignItems='center' gap={3}>
          <Flex gap={2}>
          {filterDates.all.map((dateText)=>(<Text fontWeight={600}>{dateText}</Text>))}
          </Flex>
          <Calendar
            selectRange={true}
            setFilterDates={setFilterDates}
            filterKey={"all"}
            iconSize={5}
          />
        </Flex>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2, xl: 2 }}
          gap="20px"
          mb="20px"
        >
          <LineCard
            data={threadsPerDay ?? null}
            categories={[{ key: "threads", title: "Threads" }]}
            colors={["#E56B20", "#B23800"]}
            isLoading={loadingPerDay || fetchingPerDay}
            title="Conversations Per Day"
            filterKey="threadsPerDay"
            setFilterDates={setFilterDates}
            xAxisTitle="Day of the Week"
            yAxisTitle=""
            showToolbar={true}
            offsetY={-40}
            categoryType="week"
            isSingle={true}
            lineColor={["#608C9B", "#728AA5", "#7A648C", "#695372"]}
            details={
              <UnorderedList mt={3}>
                <ListItem><Text fontWeight='700'>Description:</Text>Displays daily engagement patterns. Helps identify peak days for user interaction.</ListItem>
                <ListItem><Text fontWeight='700'>Benefit:</Text>Optimizes staffing and resources on high interaction days.</ListItem>
              </UnorderedList>
            }
          />

          <BarCard
            data={threadsPerDay ?? null}
            title="Conversations Per Day"
            filterKey="threadsPerDay"
            setFilterDates={setFilterDates}
            xAxisTitle="Day of the Week"
            yAxisTitle="Total Threads"
            isLoading={loadingPerDay || fetchingPerDay}
            type="week"
            barColor={"#E56B20"}
            showToolbar={true}
            offsetY={-35}
            details={
              <UnorderedList mt={3}>
                <ListItem><Text fontWeight='700'>Description:</Text>Shows total conversations per day. Highlights busiest days for staffing needs.</ListItem>
                <ListItem>
                <Text fontWeight='700'>Benefit:</Text>
                  Ensures adequate support during peak days to improve customer
                  service.
                </ListItem>
              </UnorderedList>
            }
          />
          <PieCard
            data={tokenUsageByDay ?? null}
            isLoading={loadingTokenDay || fetchingTokenDay}
            title="Token Usage By Day"
            filterKey="tokenUsageByDay"
            setFilterDates={setFilterDates}
            colors={["#E56B20", "#B23800", "#ED8936"]}
            type="donut"
            showToolbar={true}
            details={<UnorderedList mt={3}>
            <ListItem>
            <Text fontWeight='700'>Description:</Text>Breaks down token usage by day. Highlights days with most content generated.
            </ListItem>
            <ListItem>
            <Text fontWeight='700'>Benefit:</Text> Plans content and marketing activities for high engagement days.
            </ListItem>
          </UnorderedList>}
          />
          <BarCard
            data={threadsPerHour ?? null}
            title="Conversations Per Hour"
            filterKey="threadsPerHour"
            setFilterDates={setFilterDates}
            xAxisTitle="Hour of the Day"
            yAxisTitle="Total Threads"
            isLoading={loadingPerHour || fetchingPerHour}
            type="day"
            barColors={["#B83560", "#F9827A"]}
            showToolbar={true}
            offsetY={-35}
            details={
              <UnorderedList mt={3}>
                <ListItem>
                <Text fontWeight='700'>Description:</Text>
                  Illustrates conversations by hour. Identifies peak times for
                  user activity.
                </ListItem>
                <ListItem>
                <Text fontWeight='700'>Benefit:</Text>
                  Adjusts staff schedules to match high activity hours for
                  better responsiveness.
                </ListItem>
              </UnorderedList>
            }
          />
          <BarCard
            data={threadsPerDateData ?? null}
            title="Conversations Per Date"
            filterKey="threadsPerDate"
            setFilterDates={setFilterDates}
            showCalendar={true}
            xAxisTitle=""
            yAxisTitle="Total Threads"
            isLoading={loadingPerDate || fetchingPerDate}
            type="date"
            barColors={["pink", "orange"]}
            showToolbar={true}
            offsetY={-35}
            details={
              <UnorderedList mt={3}>
                <ListItem>
                <Text fontWeight='700'>Description:</Text>
                  Displays conversations by date. Helps track daily engagement
                  over time.
                </ListItem>
                <ListItem>
                <Text fontWeight='700'>Benefit:</Text>
                  Monitors promotional impacts and daily operational
                  effectiveness.
                </ListItem>
              </UnorderedList>
            }
          />
          <BarCard
            data={threadsOverTime ?? null}
            title="Conversations Over Time"
            filterKey="threadsOverTime"
            setFilterDates={setFilterDates}
            showCalendar={true}
            xAxisTitle=""
            yAxisTitle="Total Threads"
            isLoading={loadingOverTime || fetchingOverTime}
            type="date"
            barColor={"#E56B20"}
            showToolbar={true}
            offsetY={-35}
            details={
              <UnorderedList mt={3}>
                <ListItem>
                <Text fontWeight='700'>Description:</Text>
                  Shows conversation trends over time. Useful for long-term
                  engagement analysis.
                </ListItem>
                <ListItem>
                <Text fontWeight='700'>Benefit:</Text>
                  Analyzes trends to inform marketing strategies and customer
                  engagement efforts.
                </ListItem>
              </UnorderedList>
            }
          />
          <PieCard
            data={tokenUsageData ?? null}
            isLoading={loadingtokenUsage || fetchingtokenUsage}
            title="Token Usage"
            filterKey="tokenUsage"
            setFilterDates={setFilterDates}
            showCalendar={true}
            colors={["#E56B20", "#E56B20", "#ED8936"]}
            type="donut"
            showToolbar={true}
            details={<UnorderedList mt={3}>
            <ListItem>
            <Text fontWeight='700'>Description:</Text> Overview of total token usage. Indicates proportion of conversational content.
            </ListItem>
            <ListItem>
            <Text fontWeight='700'>Benefit:</Text> Assesses overall engagement and adjusts strategies for content delivery.
            </ListItem>
          </UnorderedList>}
          />
          <LineCard
            data={threadsDistribution ?? null}
            colors={["#E56B20", "#B23800"]}
            categories={[{ key: "threads", title: "Threads" }]}
            title="Conversations Message Count Distribution"
            filterKey="threadsDistribution"
            setFilterDates={setFilterDates}
            showCalendar={true}
            isLoading={loadingDistribution || fetchingDistribution}
            type="date"
            xAxisTitle="Messages"
            yAxisTitle="Threads "
            showToolbar={true}
            offsetY={-25}
            lineColor={["#D2A36B", "#C75B66", "#AD48A1"]}
            isSingle={true}
            markerSize={5}
            details={
              <UnorderedList mt={3}>
                <ListItem>
                <Text fontWeight='700'>Description:</Text>
                  Distribution of conversations by message count. Highlights
                  interaction depth.
                </ListItem>
                <ListItem>
                <Text fontWeight='700'>Benefit:</Text>
                  Identifies frequently asked questions and areas needing
                  detailed responses.
                </ListItem>
              </UnorderedList>
            }
          />
          <BarCard
            data={threadsDistribution ?? null}
            title={"Threads Duration"}
            filterKey={"threadsDistribution"}
            setFilterDates={setFilterDates}
            xAxisTitle=""
            yAxisTitle="Durations"
            isLoading={loadingDistribution || fetchingDistribution}
            type="date"
            barColors={["#DE857A", "#91AFA7"]}
            showToolbar={true}
            offsetY={-25}
            details={
              <UnorderedList mt={3}>
                <ListItem>
                <Text fontWeight='700'>Description:</Text>
                  Displays conversation duration. Helps understand user
                  engagement length.
                </ListItem>
                <ListItem>
                <Text fontWeight='700'>Benefit:</Text>
                  Improves service quality by addressing long-duration queries
                  more efficiently.
                </ListItem>
              </UnorderedList>
            }
          />
          <BarCard
            data={tokenUsageByHour ?? null}
            title="Token Usage By Hour"
            filterKey="tokenUsageByHour"
            setFilterDates={setFilterDates}
            xAxisTitle=""
            yAxisTitle="Total Threads"
            isLoading={loadingTokenHour || fetchingTokenHour}
            type="date"
            barColors={["#C5C0B5", "#54CBB1"]}
            showToolbar={true}
            offsetY={-25}
            details={
              <UnorderedList mt={3}>
                <ListItem>
                <Text fontWeight='700'>Description:</Text>
                  Shows tokens used per hour. Reflects interaction verbosity
                  throughout the day.
                </ListItem>
                <ListItem>
                <Text fontWeight='700'>Benefit:</Text>
                  Allocates resources for periods of high interaction to
                  maintain service quality.
                </ListItem>
              </UnorderedList>
            }
          />
          <PieCard
            data={threadsUserPersonasData ?? null}
            isLoading={
              loadingThreadsUserPersonas || fetchingThreadsUserPersonas
            }
            title="User Personas"
            filterKey="threadsUserPersonas"
            setFilterDates={setFilterDates}
            showCalendar={false}
            colors={["#E56B20", "#B23800", "#ED8936"]}
            type="donut"
            showToolbar={true}
            details={
              <UnorderedList mt={3}>
                <ListItem>
                <Text fontWeight='700'>Description:</Text>
                  Categorizes users by personas. Provides insight into user
                  types and engagement levels.
                </ListItem>
                <ListItem>
                <Text fontWeight='700'>Benefit:</Text>
                  Tailors services and marketing to different user groups for
                  better targeting.
                </ListItem>
              </UnorderedList>
            }
          />
          <ScatterCard
            data={threadsTokenUsageData ?? null}
            isLoading={loadingthreadsToken || fetchingthreadsToken}
            title="Threads Token Usage"
            filterKey="threadsTokenUsage"
            setFilterDates={setFilterDates}
            showCalendar={true}
            xAxisTitle="Tokens In"
            yAxisTitle="Tokens Out"
            showToolbar={true}
          />
        </SimpleGrid>
      </Box>
    );
  else
    return (
      <Box h="70vh">
        <Flex justifyContent="center" alignItems="center" height="100%">
          <Loader />
        </Flex>
      </Box>
    );
};

export default Statistics;
