import React from "react";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import BarChart from "components/charts/BarChart";
import Loader from "components/loader/Loader";
import { barChartData, barChartOptions } from "constants/charts";
import ChartTooltip from "tooltip/ChartTooltip";
import Calendar from "components/calendar";

const BarCard = (props) => {
  const {
    title,
    data,
    xAxisTitle,
    yAxisTitle,
    barColors,
    type,
    isLoading,
    showToolbar,
    offsetY,
    details,
    filterKey,
    setFilterDates,
    showCalendar,
    ...rest
  } = props;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const heading = useColorModeValue("#000", "secondaryGray.300");
  const cardBg = useColorModeValue("white", "#323232");

  return (
    <Card bg={cardBg} direction="column" w="100%" {...rest}>
      <Flex w="100%" alignItems="center" gap={2}>
        <Text color={heading} fontSize="22px" fontWeight="700">
          {title}
        </Text>
        <ChartTooltip details={details} />

        {showCalendar && (
          <Calendar
            selectRange={true}
            setFilterDates={setFilterDates}
            filterKey={filterKey}
          />
        )}

        {/* <Select
          fontSize="sm"
          variant="subtle"
          defaultValue="monthly"
          width="unset"
          fontWeight="700"
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </Select> */}
      </Flex>

      <Box h="400px" mt="auto" minH="420px">
        {isLoading || !data ? (
          <Loader />
        ) : (
          <BarChart
            chartData={barChartData(data)}
            chartOptions={barChartOptions(
              data,
              xAxisTitle,
              yAxisTitle,
              textColor,
              type,
              barColors,
              showToolbar,
              offsetY
            )}
          />
        )}
      </Box>
    </Card>
  );
};

export default BarCard;
