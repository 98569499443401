import { AspectRatio, Box, Button, Flex, FormControl, FormLabel, Input, useColorModeValue } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getStreamingAvatar } from '../api/streaming';


const ViewStreaming = () => {
    const { id } = useParams();
    const [streamingData, setStreamingData] = useState({});
    const navigate = useNavigate();
    const assistantId = useSelector(
        (state) => state.admin.selectedAssistant
    );
    const tableBackground = useColorModeValue("white", "#323232");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const avatarData = await getStreamingAvatar(assistantId?.id, id);
                setStreamingData(avatarData);
                
            } catch (error) {
                console.error('',error)
            }
        };

        fetchData();
    }, [assistantId?.id, id]);
  
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} position="relative">
        <Button variant='brand' bg='black' mb={3} onClick={()=>navigate(-1)}>Back</Button>
    <Flex justifyContent='space-between' flexWrap='wrap' gap={4}>
        <Box width={{ base: '100%', lg: '49%' }} >
            <Box p={4} border={1} borderRadius={10} bg={tableBackground}>
                <FormControl id="voiceId" mb={4}>
                    <FormLabel>Voice ID</FormLabel>
                    <Input
                        type="text"
                        value={streamingData?.voice_id}
                        placeholder="Enter voice ID"
                    />
                </FormControl>
                <FormControl id="avatarId" mb={4}>
                    <FormLabel>Avatar ID</FormLabel>
                    <Input
                        type="text"
                        value={streamingData?.avatar_id}
                        placeholder="Enter avatar ID"
                    />
                </FormControl>
                <FormControl id="posterUrl" mb={4}>
                    <FormLabel>Poster URL</FormLabel>
                    <Input
                        type="text"
                        value={streamingData?.poster_url}
                        placeholder="Enter poster URL"
                    />
                </FormControl>
            </Box>
        </Box>
        <Box width={{ base: '100%', lg: '49%' }}>
                <AspectRatio maxW="800px" ratio={1}>
                    <iframe
                        width="100%"
                        height="100%"
                        src={`https://app.askmeai.co/chats/streaming-avatar/${assistantId?.url}`}
                        title="Streaming Avatar"
                        style={{ border: '1px solid black' }}
                    />
                </AspectRatio>
            </Box>
        </Flex>
    </Box>
  )
}

export default ViewStreaming