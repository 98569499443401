import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useColorModeValue
} from '@chakra-ui/react';
import { updateSuggestedPrompt } from '../api/prompt';

const EditPrompt = ({ isOpen, onClose, item, onUpdate }) => {
  const cardBackground = useColorModeValue("white", "#323232");
  const [suggestion, setSuggestion] = useState('');

  useEffect(() => {
    if (item) {
      setSuggestion(item.suggestion);
    }
  }, [item]);

  const handleInputChange = (e) => {
    setSuggestion(e.target.value);
  };

  const handleSave = async () => {
    if (item) {
        try {
            const updatedItem = await updateSuggestedPrompt(item.assistant_id, item.id, suggestion);
            if (updatedItem) {
                onUpdate(updatedItem, 'update'); 
            } else {
                console.error('No updated item returned');
            }
        } catch (error) {
            console.error('Error updating suggested prompt:', error);
        }
        onClose();
    }
};



  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter='blur(10px) hue-rotate(90deg)' />
      <ModalContent bg={cardBackground}>
        <ModalHeader>Edit Suggestion</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {item && (
            <Input
              value={suggestion}
              onChange={handleInputChange}
              placeholder="Edit suggestion"
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="orange" bg="#E56B20" color="white" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost" onClick={handleSave}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditPrompt;
