import { Box, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import EditPrompt from './EditPrompt';
import DeletePrompt from './DeletePrompt';
import { deleteSuggestedPrompt } from '../api/prompt';

const PromptTable = ({ data, onUpdate, assistantId }) => {
    const tableBackground = useColorModeValue("white", "#323232");
    const tableBorder = useColorModeValue("gray.200", "#434343");
    const excludedHeaders = ['assistant_id','created_at'];
    const baseHeaders = data.length > 0 
       ? Object.keys(data[0]).filter(header => !excludedHeaders.includes(header)) 
       : [];
   const customHeaders = ['Created Date', 'Created Time'];
   const headers = [
       ...baseHeaders.slice(0, 1),
       ...customHeaders,
       ...baseHeaders.slice(1)
   ];
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const [selectedItem, setSelectedItem] = useState(null);

    const handleEditClick = (item) => {
        setSelectedItem(item);
        onEditOpen();
    };

    const handleDeleteClick = (item) => {
      setSelectedItem(item);
      onDeleteOpen();
  };

  const handleDeleteConfirm = async () => {
      if (selectedItem) {
          try {
              const response = await deleteSuggestedPrompt(assistantId, selectedItem.id);
              if (response.message === 'Suggested prompt deleted successfully') {
                  onUpdate(selectedItem.id);
                  setSelectedItem(null); 
              }
          } catch (error) {
              console.error('Failed to delete prompt:', error);
          }
          onDeleteClose();
      }
  };

    return (
        <Box w="100%" overflowX="auto" bg={tableBackground} p={4} marginTop={4} borderRadius={10}>
            <Table variant="simple">
                <Thead>
                    <Tr>
                    {headers.map((header) => (
                        <Th key={header} fontWeight={700} color="gray.400" borderColor={tableBorder}>
                            {header.replace(/_/g, ' ')}
                        </Th>
                    ))}
                        <Th fontWeight={700} color="gray.400" borderColor={tableBorder}>Action</Th> 
                    </Tr>
                </Thead>
                <Tbody>
                    {data.length > 0 ? data.map((item) => (
                        <Tr key={item.id}>
                            <Td border="none" fontSize={12}>{item.id}</Td>
                            <Td border="none" fontSize={12}>{new Date(item?.created_at).toLocaleDateString()}</Td>
                            <Td border="none" fontSize={12}>{new Date(item?.created_at).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}</Td>
                            <Td border="none" fontSize={12}>{item.suggestion}</Td>
                            <Td border="none" display="flex" gap={3}>
                                <MdEdit cursor="pointer" size={18} onClick={() => handleEditClick(item)} />
                                <MdDelete cursor="pointer" size={18} color='red' onClick={() => handleDeleteClick(item)} />
                            </Td>
                        </Tr>
                    )) : (
                        <Tr>
                            <Td colSpan="4" textAlign="center">No data available</Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>

            <EditPrompt isOpen={isEditOpen} onClose={onEditClose} item={selectedItem} onUpdate={onUpdate} />
            <DeletePrompt
                isOpen={isDeleteOpen}
                onClose={onDeleteClose}
                onConfirm={handleDeleteConfirm}
                assistantId={assistantId}
                item={selectedItem}
                onUpdate={onUpdate}
            />
        </Box>
    );
};

export default PromptTable;
