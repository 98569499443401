import { Box, Button, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import React from 'react';
import { deleteStreamingAvatar } from '../api/streaming';

const DeleteStreaming = ({ isOpen, onClose, assistantId, item, onUpdate }) => {
  const toast = useToast();
  const cardBackground = useColorModeValue("white", "#323232");
  const subCardBackground = useColorModeValue("#f9f9f9", "#434343");
  const textColor = useColorModeValue('#000', '#fff');

  const handleConfirm = async () => {
    if (item) {
      try {
        const response = await deleteStreamingAvatar(assistantId?.id, item.id);
        if (response) {
          onUpdate(item, 'delete');
          toast({
            title: 'Deleted',
            description: 'Streaming avatar has been deleted.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to delete streaming avatar.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        console.error("Failed to delete streaming avatar", error);
      } finally {
        onClose();
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter='blur(10px) hue-rotate(90deg)' />
      <ModalContent maxWidth='5xl' bg={cardBackground}>
        <ModalHeader>Delete Streaming Avatar</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading size='md' mb={3}>Are you sure you want to delete this item ?</Heading>
          <Box p={4} borderRadius={10} bg={subCardBackground}>
            <Flex mb={3} color='black' justifyContent='space-between' flexWrap='wrap'>
              <Text color={textColor}><strong>ID:</strong> {item?.id} </Text>
              <Text color={textColor}><strong>Voice ID:</strong> {item?.voice_id} </Text>
            </Flex>
            <Flex color='black' justifyContent='space-between' flexWrap='wrap'>
              <Text color={textColor}><strong>Avatar ID:</strong> {item?.avatar_id} </Text>
              <Text color={textColor}><strong>Created at:</strong> {item?.created_at} </Text>
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} bg='red' color='white' onClick={handleConfirm}>
            Confirm
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteStreaming;
