import { Box, Button } from '@chakra-ui/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAssistantId } from 'store/slices/admin';

const AssistantTooltip = ({activeAssistnat}) => {
    const assistantId = useSelector(
        (state) => state.admin.selectedAssistant?.id
    );
    const dispatch = useDispatch();
    return (
        <Box position='absolute' top='20px' right='20px' display={activeAssistnat === assistantId ? 'none' : 'block'}>
            <Button fontSize={15} px={3} py={0} onClick={() => dispatch(setSelectedAssistantId(activeAssistnat))}>Switch To Assistant</Button>
        </Box>
    )
}

export default AssistantTooltip
