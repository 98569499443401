import { Box, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdDelete, MdEdit, MdVisibility } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import TruncatedCell from 'views/admin/announcement/components/TruncardCell';
import DeleteStreaming from './DeleteStreaming';

const StreamingTable = ({ data, assistantId, onUpdate }) => {
  const tableBackground = useColorModeValue("white", "#323232");
  const tableBorder = useColorModeValue("gray.200", "#434343");
  const excludedHeaders = ['assistant_id', 'assistants','default'];
  const [selectedItem, setSelectedItem] = useState(null);
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const headers = data.length > 0 ? Object.keys(data[0]).filter(header => !excludedHeaders.includes(header)) : [];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime()) ? date.toLocaleString() : "Invalid Date";
  };

  const navigate = useNavigate();

  const handleEditClick = (item) => {
    console.log(item);
    navigate(`/streaming-avatar/${item.id}?edit=true`);
  };

  const handleViewClick = (item) => {
    console.log(item);
    navigate(`/streaming-avatar/${item.id}`);
  };

  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    onDeleteOpen();
  };

  return (
    <Box w="100%" overflowX="auto" bg={tableBackground} p={4} marginTop={4} borderRadius={10}>
      <Table variant="simple">
        <Thead>
          <Tr>
            {headers.map((header) => (
              <Th key={header} fontWeight={700} px="20px" color="gray.400" borderColor={tableBorder}>
                {header.replace(/_/g, ' ')}
              </Th>
            ))}
            <Th fontWeight={700} px="20px" color="gray.400" borderColor={tableBorder}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.length > 0 ? data.map((item) => (
            <Tr key={item.id}>
              <TruncatedCell text={item.id?.toString() || ''} length={20} />
              <TruncatedCell text={formatDate(item.created_at)} length={30} />
              <TruncatedCell text={item.avatar_id?.toString() || ''} length={20} />
              <TruncatedCell text={item.voice_id?.toString() || ''} length={20} />
              <TruncatedCell text={item.poster_url?.toString() || ''} length={20} />
              <Td px="20px" border="none" display="flex" gap={3}>
                <MdVisibility cursor="pointer" size={18} onClick={() => handleViewClick(item)} />
                <MdEdit cursor="pointer" size={18} onClick={() => handleEditClick(item)} />
                <MdDelete cursor="pointer" size={18} color='red' onClick={() => handleDeleteClick(item)} />
              </Td>
            </Tr>
          ))
            : (
              <Tr>
                <Td>No data available</Td>
              </Tr>
            )
          }
        </Tbody>
      </Table>
      <DeleteStreaming isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        assistantId={assistantId}
        item={selectedItem}
        onUpdate={onUpdate}
        />
    </Box>
  )
}

export default StreamingTable;
