import { Box, Button, Flex, FormControl, FormLabel, Input, Select, Text, Textarea, useColorModeValue, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { createAssistant } from '../api/assist';
import { useSelector } from 'react-redux';

const NewAssistant = () => {
    const UserId = useSelector(
        (state) => state.admin.selectedAssistant?.user_id
    );
    console.log(UserId);
    
    const cardBackground = useColorModeValue("white", "#323232");
    const navigate = useNavigate();
    const [assistName, setAssistName] = useState('');
    const [assistant_id, setAssistant_id] = useState('');
    const [url, setUrl] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [typeAssist, setTypeAssist] = useState('');
    const [websiteIconUrl, setWebsiteIconUrl] = useState('');
    const [widgetCode, setWidgetCode] = useState('');
    const [description, setDescription] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [openaiOrganizationId, setOpenaiOrganizationId] = useState(''); 
    const [openaiProjectId, setOpenaiProjectId] = useState('');
    // const [adminId, setAdminId] = useState('f60646e2-89d9-4b64-ab06-ba93d3e8edab'); 
    const [error, setError] = useState(null);
    const toast = useToast();
  
    const handleSubmit = async () => {
      if (!assistName || !assistant_id || !url || !logoUrl || !websiteUrl || !typeAssist || !apiKey || !openaiOrganizationId || !openaiProjectId) {
        setError('Please fill in all required fields.');
        return;
      }
      setError(null);
      const requestData = {
        assistant: {
          id: assistant_id,
          name: assistName,
          description: description,
          url: url,
          logo_url: logoUrl,
          type: typeAssist,
          widget_code: widgetCode,
          website_url: websiteUrl,
          widget_icon_url: websiteIconUrl,
        },
        apiKey: {
          api_key: apiKey,
          openai_organization_id: openaiOrganizationId,
          openai_project_id: openaiProjectId,
        },
      };
      console.log('assistant data', requestData);
      try {
        const result = await createAssistant(requestData);
        // console.log('Assistant created successfully:', result);
        if(result){
          toast({
            title: "Assistant Created.",
            description: "Your assistant has been created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setAssistName('');
          setAssistant_id('');
          setUrl('');
          setLogoUrl('');
          setWebsiteUrl('');
          setTypeAssist('');
          setWebsiteIconUrl('');
          setWidgetCode('');
          setDescription('');
          setApiKey('');
          setOpenaiOrganizationId('');
          setOpenaiProjectId('');

          navigate('/settings');
        }
        
      } catch (error) {
        console.error('Failed to create assistant:', error);
        toast({
          title: "Error Creating Assitant",
          description: {error},
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setError('Failed to create assistant. Please try again.');
      }
    };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "60px" }} position="relative">
    <Flex justifyContent='center' flexWrap='wrap' gap={4}>
      <Box width="100%">
        <Box p={4} border={1} borderRadius={10} bg={cardBackground}>
          <Flex gap={3}>
            <FormControl id="assistantName" mb={4} isRequired>
              <FormLabel>Assistant Name</FormLabel>
              <Input
                type="text"
                value={assistName}
                onChange={(e) => setAssistName(e.target.value)}
                placeholder="Please enter assistant name..."
              />
              <Text fontSize={13} fontStyle='italic' color='#c7c7c7'>The name of your assistant</Text>
            </FormControl>
            <FormControl id="assistant_id" mb={4} isRequired>
              <FormLabel>Assistant ID</FormLabel>
              <Input
                type="text"
                value={assistant_id}
                onChange={(e) => setAssistant_id(e.target.value)}
                placeholder="Please enter assistant ID..."
              />
            </FormControl>
          </Flex>
          <Flex gap={3}>
            <FormControl id="url" mb={4} isRequired>
              <FormLabel>Assistant URL</FormLabel>
              <Input
                type='url'
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Please enter assistant URL..."
              />
            </FormControl>
            <FormControl id="logo_url" mb={4} isRequired>
              <FormLabel>Logo URL</FormLabel>
              <Input
                type="url"
                value={logoUrl}
                onChange={(e) => setLogoUrl(e.target.value)}
                placeholder="Please enter your logo URL..."
              />
            </FormControl>
          </Flex>
          <Flex gap={3}>
            <FormControl id="website_url" mb={4} isRequired>
              <FormLabel>Website URL</FormLabel>
              <Input
                type='url'
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
                placeholder="Please enter website URL..."
              />
            </FormControl>
            <FormControl id="type" mb={4} isRequired>
              <FormLabel>Type</FormLabel>
              <Select
                placeholder='Please select assistant type'
                value={typeAssist}
                onChange={(e) => setTypeAssist(e.target.value)}
              >
                <option value="b2b">b2b</option>
                <option value="b2c">b2c</option>
              </Select>
            </FormControl>
          </Flex>
          <FormControl id="website_icon_url" mb={4}>
            <FormLabel>Widget Icon URL</FormLabel>
            <Input
              type="url"
              value={websiteIconUrl}
              onChange={(e) => setWebsiteIconUrl(e.target.value)}
              placeholder="Please enter widget icon URL..."
            />
          </FormControl>
          <FormControl id="widget_code" mb={4}>
            <FormLabel>Widget Code</FormLabel>
            <Textarea
              resize='none'
              value={widgetCode}
              onChange={(e) => setWidgetCode(e.target.value)}
              minHeight='100px'
              placeholder='Please enter your widget code...'
            />
          </FormControl>
          <FormControl id="description" mb={4}>
            <FormLabel>Assistant Description</FormLabel>
            <Textarea
              resize='none'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              minHeight='100px'
              placeholder='Please enter assistant description...'
            />
          </FormControl>
          <Flex gap={3}>
            <FormControl id="api_key" mb={4} isRequired>
              <FormLabel>API Key</FormLabel>
              <Input
                type="text"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                placeholder="Please enter API key..."
              />
            </FormControl>
            <FormControl id="openai_organization_id" mb={4} isRequired>
              <FormLabel>OpenAI Organization ID</FormLabel>
              <Input
                type="text"
                value={openaiOrganizationId}
                onChange={(e) => setOpenaiOrganizationId(e.target.value)}
                placeholder="Please enter OpenAI organization ID..."
              />
            </FormControl>
          </Flex>
          <FormControl id="openai_project_id" mb={4} isRequired>
            <FormLabel>OpenAI Project ID</FormLabel>
            <Input
              type="text"
              value={openaiProjectId}
              onChange={(e) => setOpenaiProjectId(e.target.value)}
              placeholder="Please enter OpenAI project ID..."
            />
          </FormControl>
          <FormControl id="admin_id" mb={4} isRequired>
            <FormLabel>Admin ID</FormLabel>
            <Input
            disabled
              type="text"
              value={UserId === null ? 'No user Id available': UserId}
              // value={adminId}
            />
          </FormControl>
          {error && <Box color="red" mb={4}>{error}</Box>}
          <Button onClick={handleSubmit} colorScheme="orange" bg='#E56B20' color='white'>
            Create Assistant
          </Button>
          <Button ml={3} variant='ghost' onClick={() => navigate(-1)}>Cancel</Button>
        </Box>
      </Box>
    </Flex>
  </Box>
  )
}

export default NewAssistant