import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue, useToast } from '@chakra-ui/react'
import React from 'react'
import { deleteAdmin } from '../api/users';

const DeleteUser = ({ isOpen, onClose, item, assistantId, onUpdate }) => {
  const cardBackground = useColorModeValue("white", "#323232");
  const toast = useToast();
  console.log(item);
  

  const handleConfirm = async (e) => {
    e.preventDefault();
    if (item && assistantId?.id) {
        console.log("Attempting to delete:", assistantId.id, item.id);
        try {
            const response = await deleteAdmin(assistantId.id, item.id);
            console.log("Deleted Item", response);

            if (response && response.message === 'Record was successfully deleted') {
                onUpdate(item, 'delete');
                onClose();
                toast({
                    title: "User deleted.",
                    description: `The user ${item?.name} was deleted successfully.`,
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                console.error("Unexpected response:", response);
                toast({
                    title: "Deletion failed.",
                    description: "There was an issue deleting the user. Please try again later.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Failed to delete user", error);
            toast({
                title: "Error.",
                description: "An error occurred while deleting the user. Please try again later.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    } else {
        console.error("Invalid item or assistantId:", item, assistantId);
        toast({
            title: "Error.",
            description: "Invalid user data. Please try again later.",
            status: "error",
            duration: 9000,
            isClosable: true,
        });
    }
};

  
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter='blur(10px) hue-rotate(90deg)' />
      <ModalContent bg={cardBackground}>
        <ModalHeader>Delete User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Are you sure you want to delete <strong>{item?.name}</strong>?</p>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} variant="brand" onClick={onClose}>Cancel</Button>
          <Button colorScheme="red" bg="#ff0000" color="#fff" onClick={handleConfirm}>Delete</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteUser;
